require('dotenv').config();

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

export const cognitoConfig = {
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID
};

export const auth0Config = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN
};

// export const backendHost = process.env.REACT_APP_BACKEND_HOST || 'https://uat.mapcurve.com/';
export const backendHost = 'https://dtube-core-fa-prod-f01.azurewebsites.net/api/';

export const docsHost = process.env.REACT_APP_DOCS_HOST;

export const stripeKey =
  process.env.STRIPE_KEY ||
  'pk_test_51Jj5GjFkKV7ngYnVmhwScmyy56k3bZr7smXgVLQOPYVgMSxf7vyRuRXHNKlOVeDCc0QJQLgV00jQR5AZKJfEc8Wx000NsCWk2I';
// 'pk_test_51JSJqRHO5G3aLMEiSfbp0AR5iRf9ejpUk3RMUz41P68Q5AlSNUzfkDmEMKvJnAx1R3Usme3yZFpocCNXlPm2Jhwl005Q3SGalr';

export const mapConfig = process.env.REACT_APP_MAP_MAPBOX;

export const googleAnalyticsConfig = process.env.REACT_APP_GA_MEASUREMENT_ID;
