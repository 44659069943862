import { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
// material
import { styled } from '@mui/system';
//
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import { Box, Typography  } from '@mui/material'
import HomeIcon from '@mui/icons-material/Home';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  // marginTop: '200px !important',
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
  // pt: '100px !important'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  zIndex: 10,
  paddingTop: APP_BAR_MOBILE + 24,
  // marginTop: '30px',
  // paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

export default function Layout() {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate()

  return (
    <Box>
      <Box sx={{ zIndex: 10009, position: 'fixed', top: 0, background: 'lightgrey', width: '100%', height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Typography>
          {/* The dTube selfupload feature is under necessary maintenance. The expected date for the relaunch of SelfUpload is 24 July 2024. */}
          {/* The dTube page feature is under necessary maintenance. We apologize for any inconvenience caused by the outage. */}
          The dTube page features are under necessary maintenance. We apologize for any inconvenience caused by the outage.
        </Typography>
      </Box>
      {/* <Box sx={{ display: 'flex', flex: 1 }}> */}
        <RootStyle>
          {/* <Box sx={{ position: 'fixed', top: 0, marginBottom: '30px', height: '30px', textAlign: 'center', color: 'red', width: '100%', zIndex: 20000, fontSize: [ '8px', '16px'], backgroundColor: 'white', lineHeight: ['15px', '30px'], boxShadow: '0 4px 4px -2px gray' }}>
            This page is under transformation. We are fixing any transformation issues now and full version of page will be available asap. Thank you for understanding.
          </Box> */}
          <Navbar /* onOpenSidebar={() => setOpen(true)} *//>
          <Sidebar /*onOpenSidebar={() => setOpen(false)} isOpenSidebar={open} onCloseSidebar={() => setOpen(false)}*/ />
          <MainStyle id='main-style'>
            <Outlet />
          </MainStyle>
          {/* <Box sx={{ display: ['initial', 'none', 'none'], position: 'fixed', bottom: 0, height: '50px', width: '100%', backgroundColor: 'lightgrey', zIndex: '10000 !important', borderTop: '1px solid blue' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Box sx={{ flex: 1, alignItems: 'center', textAlign: 'center', width: '100%', display: 'flex', flexDirection: 'column', borderRight: '1px solid grey' }} onClick={() => { window.location.href = '/' }}>
                <HomeIcon sx={{ fontSize: 30 }} />
                <Typography sx={{ mt: -0.5 }}>
                  Home
                </Typography>
              </Box>
              <Box sx={{ flex: 1, alignItems: 'center', textAlign: 'center', width: '100%', display: 'flex', flexDirection: 'column', borderRight: '1px solid grey' }} onClick={() => { window.location.href = '/featured' }}>
                <StarBorderIcon sx={{ fontSize: 30 }} />
                <Typography sx={{ mt: -0.5 }}>
                  Featured
                </Typography>
              </Box>
              <Box sx={{ flex: 1, alignItems: 'center', textAlign: 'center', width: '100%', display: 'flex', flexDirection: 'column' }} onClick={() => { window.location.href = '/uservideos' }}>
                <VideoLibraryIcon sx={{ fontSize: 30 }} />
                <Typography sx={{ mt: -0.5 }}>
                  My videos
                </Typography>
              </Box>
            </Box>
          </Box> */}
        </RootStyle>
      {/* </Box> */}
    </Box>
  );
}
