import { createContext, ReactNode, useEffect, useReducer } from 'react';
// utils
import axios from '../utils/axios';
// @types
import { ActionMap, AuthState, AuthUser, SanctumContextType } from '../@types/authentication';
// ----------------------------------------------------------------------

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER'
}

type SanctumAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.Login]: {
    user: AuthUser;
  };
  [Types.Logout]: undefined;
  [Types.Register]: {
    user: AuthUser;
  };
};

export type SanctumActions = ActionMap<SanctumAuthPayload>[keyof ActionMap<SanctumAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const SanctumReducer = (state: AuthState, action: SanctumActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };

    case 'REGISTER':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user
      };

    default:
      return state;
  }
};

const AuthContext = createContext<SanctumContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(SanctumReducer, initialState);

  const setUserData = (user) => {
    console.log(user);
    window.localStorage.setItem('email', user.email);
    window.localStorage.setItem('isLoggedIn', 'true');
    window.localStorage.setItem('userStatus', user.status);
    window.localStorage.setItem('gravatar', user.gravatar);
    window.localStorage.setItem('gmail_avatar', user.gmail_avatar);
    window.localStorage.setItem('redirect_path', user.redirect_path || '');
  };

  // useEffect(() => {
  //   const initialize = async () => {
  //     try {
  //       if (!state.isAuthenticated) {
  //         const response = await axios.get('api/user');
  //         const [user] = response.data;
  //         if (user) setUserData(user);
  //         dispatch({
  //           type: Types.Initial,
  //           payload: {
  //             isAuthenticated: true,
  //             user
  //           }
  //         });
  //       } else {
  //         dispatch({
  //           type: Types.Initial,
  //           payload: {
  //             isAuthenticated: false,
  //             user: null
  //           }
  //         });
  //       }
  //     } catch (err) {
  //       console.error(err);
  //       dispatch({
  //         type: Types.Initial,
  //         payload: {
  //           isAuthenticated: false,
  //           user: null
  //         }
  //       });
  //     }
  //   };

  //   initialize();
  // }, []);

  const getProfile = async () => {
    const response = await axios.get('api/user');
    const [user] = response.data;
    if (user) setUserData(user);
    dispatch({
      type: Types.Login,
      payload: {
        user
      }
    });
  };

  const CSRFToken = async () => {
    await axios.get('api/csrf-cookie');
  };

  const login = async (email: string, password: string) => {
    await CSRFToken();
    const response = await axios.post('api/login', {
      email,
      password
    });
    if (response.status === 200) {
      const { user } = response.data;
      setUserData(user);
      dispatch({
        type: Types.Login,
        payload: {
          user
        }
      });
    }
  };

  const relogin = async (id: string) => {
    await CSRFToken();
    const response = await axios.post('api/user/relogin', { id });
    if (response.status === 200) {
      window.localStorage.clear();
      const { user } = response.data;
      setUserData(user);
      dispatch({
        type: Types.Login,
        payload: {
          user
        }
      });
      window.location.href = '/dashboard';
    }
  };

  const register = async (
    email: string,
    password: string,
    firstname: string,
    lastname: string,
    password_confirmation: string,
    country: string
  ) => {
    await axios.post('api/register', {
      email,
      password,
      firstname,
      lastname,
      password_confirmation,
      country
    });
    const response = await axios.get('api/user');
    const [user] = response.data;
    if (user) setUserData(user);
    // const { user } = response.data;
    dispatch({
      type: Types.Register,
      payload: {
        user
      }
    });
  };

  const logout = async (params = '') => {
    // await axios.post('api/logout');
    localStorage.clear();
    sessionStorage.clear();
    dispatch({ type: Types.Logout });
    // window.location.href = `/login${params}`;
    // window.location.href = '/'
  };

  const resetPassword = async (email: string) => {
    const { data } = await axios.post('api/reset-password', { email });
  };

  const updateProfile = async (values) => {
    const { data } = await axios.post('api/user', values);
    // console.log(data);
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'sanctum',
        login,
        logout,
        register,
        resetPassword,
        updateProfile,
        relogin,
        getProfile
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
