import { useEffect, useState, useRef } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
// material
import { Icon } from '@iconify/react';
import MenuIcon from '@mui/icons-material/Menu';
import { alpha, styled } from '@mui/system';
import { Box, Link, Button, Drawer, Typography, IconButton, Stack, Switch } from '@mui/material';
// hooks
// import useAuth from '../../hooks/useAuth';
// routes
// import { PATH_DASHBOARD, PATH_DOCS } from '../../routes/paths';
// components
import Logo from '../components/Logo';
import MyAvatar from '../components/MyAvatar';
import Scrollbar from '../components/Scrollbar';
// import NavSection from '../../components/NavSection';
import { MHidden } from '../components/@material-extend';
//
import { useSidebarConfig } from './SidebarConfig';
// import { DocIcon } from '../../assets';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import DoneIcon from '@mui/icons-material/Done';
import CategoryIcon from '@mui/icons-material/Category';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { api } from '../service/';
import LanguageIcon from '@mui/icons-material/Language';
import SettingsIcon from '@mui/icons-material/Settings';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import VideocamIcon from '@mui/icons-material/Videocam';
import CircleIcon from '@mui/icons-material/Circle';
import BarChartIcon from '@mui/icons-material/BarChart';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 300;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.grey[500_12]
}));

const DocStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(2.5),
  borderRadius: theme.shape.borderRadius,
  backgroundColor:
    theme.palette.mode === 'light'
      ? alpha(theme.palette.primary.main, 0.08)
      : theme.palette.primary.lighter
}));

// ----------------------------------------------------------------------

type DashboardSidebarProps = {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
  onOpenSidebar: VoidFunction;
};

let menus = [
  // {
  //   icon: <FiberManualRecordIcon fontSize='large' color='inherit' sx={{ color: 'red' }} />,
  //   text: 'LIVE STREAM',
  //   route: '/',
  //   selected: false
  // },
  {
    icon: <CircleIcon fontSize='large' sx={{ color: '#024e7b', width: 0 }} />,
    text: 'LIVE STREAM',
    route: '/livestreams',
    // selected: false,
    lead_admin: false,
    approver: false,
    approver_dce: false,
    user: true,
    data_user: false,
    livestream_admin: false,
    admin_coordinator: false,
    admin_coordinator_dce: false,
    
  },
  {
    icon: <StarBorderIcon fontSize='large' sx={{ color: 'white' }} />,
    text: 'FEATURED',
    route: '/featured',
    // selected: false,
    lead_admin: false,
    approver: false,
    approver_dce: false,
    user: true,
    data_user: false,
    livestream_admin: false,
    admin_coordinator: false,
    admin_coordinator_dce: false,
  },
  
  {
    icon: <VideoLibraryIcon fontSize='large' sx={{ color: 'white' }}  />,
    text: 'VIDEO LIBRARY',
    route: '/videolibrary',
    lead_admin: false,
    approver: false,
    approver_dce: false,
    user: true,
    data_user: false,
    livestream_admin: false,
    admin_coordinator: false,
    admin_coordinator_dce: false,
  },
  {
    icon: <VideoLibraryIcon fontSize='large' sx={{ color: 'white' }}  />,
    text: 'POSTS',
    route: '/',
    lead_admin: true,
    approver: false,
    approver_dce: false,
    user: false,
    data_user: false,
    livestream_admin: false,
    admin_coordinator: true,
    admin_coordinator_dce: true,
    unselect: true
  },
  {
    text: 'All posts',
    route: '/',
    lead_admin: true,
    approver: false,
    approver_dce: false,
    user: false,
    data_user: false,
    livestream_admin: false,
    admin_coordinator: true,
    admin_coordinator_dce: true,
  },
  {
    text: 'Add new',
    route: '/upload',
    lead_admin: true,
    approver: false,
    approver_dce: false,
    user: false,
    data_user: false,
    livestream_admin: false,
    admin_coordinator: true,
    admin_coordinator_dce: true,
  },
  {
    text: 'Categories',
    route: '/categories',
    lead_admin: true,
    approver: false,
    approver_dce: false,
    user: false,
    data_user: false,
    livestream_admin: false,
    admin_coordinator: true,
    admin_coordinator_dce: true,
  },
  {
    text: 'Tags',
    route: '/tags',
    lead_admin: true,
    approver: false,
    approver_dce: false,
    user: false,
    data_user: false,
    livestream_admin: false,
    admin_coordinator: true,
    admin_coordinator_dce: true,
  },
  {
    icon: <DoneIcon fontSize='large' sx={{ color: 'white' }}  />,
    text: 'UPLOADED VIDEOS',
    route: '/uploaded',
    lead_admin: true,
    approver: false,
    approver_dce: false,
    user: false,
    data_user: false,
    livestream_admin: false,
    admin_coordinator: true,
    admin_coordinator_dce: true,
  },
  {
    icon: <RemoveCircleIcon fontSize='large' sx={{ color: 'white' }}  />,
    text: 'VIDEOS TO DELETE',
    route: '/deleted',
    lead_admin: true,
    approver: false,
    approver_dce: false,
    user: false,
    data_user: false,
    livestream_admin: false,
    admin_coordinator: true,
    admin_coordinator_dce: true,
  },
  {
    icon: <DoneIcon fontSize='large' sx={{ color: 'white' }}  />,
    text: 'UPLOADED VIDEOS',
    route: '/',
    lead_admin: false,
    approver: true,
    approver_dce: true,
    user: false,
    data_user: false,
    livestream_admin: false,
    admin_coordinator: false,
    admin_coordinator_dce: false,
  },
  // {
  //   icon: <VideoLibraryIcon fontSize='large' sx={{ color: 'white' }}  />,
  //   text: 'MY VIDEOS',
  //   route: '/myvideos',
  //   lead_admin: false,
  //   approver: true,
  //   user: true
  //   // selected: true
  // },
  {
    icon: <BusinessCenterIcon fontSize='large' sx={{ color: 'white' }}  />,
    text: 'BUSINESS LINES',
    route: '/businesslines',
    lead_admin: true,
    approver: false,
    approver_dce: false,
    user: false,
    data_user: false,
    livestream_admin: false,
    admin_coordinator: false,
    admin_coordinator_dce: false,
  },
  {
    icon: <LanguageIcon fontSize='large' sx={{ color: 'white' }}  />,
    text: 'COUNTRIES',
    route: '/countries',
    lead_admin: true,
    approver: false,
    approver_dce: false,
    user: false,
    data_user: false,
    livestream_admin: false,
    admin_coordinator: false,
    admin_coordinator_dce: false,
  },
  {
    icon: <EqualizerIcon fontSize='large' sx={{ color: 'white' }}  />,
    text: 'STATISTICS',
    route: '/statistics',
    lead_admin: true,
    approver: false,
    approver_dce: false,
    user: false,
    data_user: false,
    livestream_admin: true,
    admin_coordinator: true,
    admin_coordinator_dce: true,
  },
  {
    icon: <EqualizerIcon fontSize='large' sx={{ color: 'white' }}  />,
    text: 'STATISTICS',
    route: '/',
    lead_admin: false,
    approver: false,
    approver_dce: false,
    user: false,
    data_user: true,
    livestream_admin: false,
    admin_coordinator: false,
    admin_coordinator_dce: false,
  },
  {
    icon: <VideocamIcon fontSize='large' sx={{ color: 'white' }}  />,
    text: 'LIVE STREAMS',
    route: '/livestreams',
    lead_admin: true,
    approver: false,
    approver_dce: false,
    user: false,
    data_user: false,
    livestream_admin: false,
    admin_coordinator: false,
    admin_coordinator_dce: false,
  },
  {
    icon: <VideocamIcon fontSize='large' sx={{ color: 'white' }}  />,
    text: 'LIVE STREAMS',
    route: '/',
    lead_admin: false,
    approver: false,
    approver_dce: false,
    user: false,
    data_user: false,
    livestream_admin: true,
    admin_coordinator: false,
    admin_coordinator_dce: false,
  },
  {
    icon: <BarChartIcon fontSize='large' sx={{ color: 'white' }}  />,
    text: 'DT ANALYTICS',
    isRemote: true,
    route: 'http://analytics.google.com/analytics/web/',
    lead_admin: true,
    approver: false,
    approver_dce: false,
    user: false,
    unselect: true,
    data_user: false,
    livestream_admin: false,
    admin_coordinator: false,
    admin_coordinator_dce: false,
  },
  {
    icon: <SettingsIcon fontSize='large' sx={{ color: 'white' }}  />,
    text: 'SETTINGS',
    route: '/basicinfo',
    lead_admin: true,
    approver: false,
    approver_dce: false,
    user: false,
    unselect: true,
    data_user: false,
    livestream_admin: false,
    admin_coordinator: false,
    admin_coordinator_dce: false,
  },
  // {
  //   text: 'Hot Topics',
  //   route: '/hottopics',
  //   lead_admin: true,
  //   approver: false,
  //   user: false,
  //   data_user: false,
  //   livestream_admin: false,
  //   admin_coordinator: false
  // },
  // {
  //   text: 'Featured',
  //   route: '/featured',
  //   lead_admin: true,
  //   approver: false,
  //   user: false,
  //   data_user: false,
  //   livestream_admin: false,
  //   admin_coordinator: false
  // },
  // {
  //   text: 'Slideshow',
  //   route: '/slideshow',
  //   lead_admin: true,
  //   approver: false,
  //   user: false,
  //   data_user: false,
  //   livestream_admin: false,
  //   admin_coordinator: false
  // },
  {
    text: 'Basic info',
    route: '/basicinfo',
    lead_admin: true,
    approver: false,
    approver_dce: false,
    user: false
  },
  {
    text: 'User Roles',
    route: '/users',
    lead_admin: true,
    approver: false,
    approver_dce: false,
    user: false
  },
  
].filter(i => i)


const rolesObj = {
  lead_admin: 'Lead Admin',
  approver: 'Approver',
  approver_dce: 'Approver DCE',
  data_user: 'Data User',
  livestream_admin: 'Livestream Admin',
  admin_coordinator: 'Admin Coordinator',
  admin_coordinator_dce: 'Admin Coordinator DCE',
}

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar, onOpenSidebar }: DashboardSidebarProps) {
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(false)
  const [menuList, setMenuList] = useState([]);
  const [categories, setCategories] = useState([])
  const [hoverCategory, setHoverCategory] = useState('');
  const [isOriginalRole, setIsOriginalRole] = useState(localStorage.getItem('role') === localStorage.getItem('originalRole'))
  const [isAdmin, setIsAdmin] = useState(localStorage.getItem('role') === 'lead_admin')
  const [isUser, setIsUser] = useState(localStorage.getItem('role') === 'user')
  const [isApprover, setIsApprover] = useState(localStorage.getItem('role') === 'approver')
  const [isApproverDCE, setIsApproverDCE] = useState(localStorage.getItem('role') === 'approver_dce')
  const [isDataUser, setIsDataUser] = useState(localStorage.getItem('role') === 'data_user')
  const [isAdminCoordinator, setIsAdminCoordinator] = useState(localStorage.getItem('role') === 'admin_coordinator')
  const [isAdminCoordinatorDCE, setIsAdminCoordinatorDCE] = useState(localStorage.getItem('role') === 'admin_coordinator_dce')
  const [isLiveStreamAdmin, setIsLiveStreamAdmin] = useState(localStorage.getItem('role') === 'livestream_admin')
  const [businessLine, setBusinessLine] = useState(localStorage.getItem('businessLine'))
  const [originalRole, setOriginalRole] = useState(localStorage.getItem('originalRole'))
  const [businessLines, setBusinessLines] = useState([])
  const [businessLineId, setBusinessLineId] = useState(localStorage.getItem('businessLineId'))
  const [role, setRole] = useState('')
  const [country, setCountry] = useState(localStorage.getItem('country'))
  const [isScroll, setIsScroll] = useState(true)
  const [sidebarScroll, setSidebarScroll] = useState(0)
  var _sidebarScroll = useRef(0)

  const navigate = useNavigate();
  //   const { user } = useAuth();

  const { sidebarConfig } = useSidebarConfig();
  // console.log(window.location.href)

  useEffect(() => {
    setSidebarScroll(_sidebarScroll.current)
  }, [_sidebarScroll.current])


  useEffect(async () => {
    const [sidebar] = document.getElementsByClassName('simplebar-content-wrapper')
    const scrollHandler = (e, cb) => {
      // setSidebarScroll(e.target.scrollTop)
      _sidebarScroll.current = e.target.scrollTop
      cb(e.target.scrollTop)
    }
    const callback = (v) => { setSidebarScroll(v) }
    if(sidebar)
      sidebar.addEventListener('scroll', (e) => scrollHandler(e, callback))
    
    const _role = localStorage.getItem('role') || '' 
    const _originalRole = localStorage.getItem('originalRole')
    const _businessLine = localStorage.getItem('businessLine')
    const _businessLineId = localStorage.getItem('businessLineId')
    const _isUser = _role === 'user'
    const _isAdmin = _role === 'lead_admin'
    const _isApprover = _role === 'approver'
    const _isApproverDCE = _role === 'approver_dce'
    const _businessLines = await api.getBusinessLines({
      page_number: 1,
      items_count: 10000
    })
    setBusinessLines(_businessLines)
    // const _businessLine = _businessLines.find(b => b.id === _businessLineId)?.name
    // console.log(_businessLines)
    setRole(_role)
    setIsOriginalRole(_role === _originalRole)
    setOriginalRole(_originalRole)
    setIsUser(_isUser)
    setIsAdmin(_isAdmin)
    setIsApprover(_isApprover)
    setIsApproverDCE(_isApproverDCE)
    setIsDataUser(localStorage.getItem('role') === 'data_user')
    setIsLiveStreamAdmin(localStorage.getItem('role') === 'livestream_admin')
    setIsAdminCoordinator(localStorage.getItem('role') === 'admin_coordinator')
    setIsAdminCoordinatorDCE(localStorage.getItem('role') === 'admin_coordinator_dce')
    setBusinessLine(_businessLine)
    setBusinessLineId(_businessLineId)
    setCountry(localStorage.getItem('country'))

    const isMobile = isMobileDevice()
    const isUser = localStorage.getItem('role') === 'user'
    if(isMobile && !isUser)
      changeRole('user', _businessLines)

    console.log(pathname)
    let _menus = menus.map((item) => {
      return {
        ...item,
        selected: !item.unselect && (pathname === item.route)
      }
    }).filter((item) => item[_role])
    console.log(_role)
    console.log(_menus)
    if(_menus[0].text === "LIVE STREAM") {
      const data = await api.searchVideo({
        search: null,
        items_count: 10000,
        page_number: 1
      })
      const found = data.find(v => v.is_stream && v.status == 'stream_started')
      if(found) {
        _menus[0].icon = <CircleIcon fontSize='large' sx={{ color: 'red' }} />
        // menus[0].route = `/video?id=${found.id}`
        setMenuList(_menus)
      }
      else {
        _menus[0].icon = <CircleIcon fontSize='large' sx={{ color: '#024e7b', width: 0 }} />
        // menus[0].route = `/video?id=${found.id}`
        setMenuList(_menus)
      }
      // .then(data => {
      //   const found = data.find(v => v.is_stream && v.status == 'stream_started')
      //   if(found) {
      //     _menus[0].icon = <CircleIcon fontSize='large' sx={{ color: 'red' }} />
      //     // menus[0].route = `/video?id=${found.id}`
      //     setMenuList(_menus)
      //   }
      //   else {
      //     _menus[0].icon = <CircleIcon fontSize='large' sx={{ color: '#024e7b', width: 0 }} />
      //     // menus[0].route = `/video?id=${found.id}`
      //     setMenuList(_menus)
      //   }
      // })
    }
    else
      setMenuList(_menus)

  }, [])

  useEffect(async () => {
    console.log('isUser', isUser)
    if(isUser) {
      const _countries = await api.getCountries({
        page_number: 1,
        items_count: 10000
      })
      const bls = await api.getBusinessLines({
        page_number: 1,
        items_count: 10000
      })
      const bl = bls.find(b => b.name === `DEFAULT_BUSINESS_LINE`)
      let _categories = await api.getCategories({
        parent_id: localStorage.getItem('businessLineId'),
        page_number: 1,
        items_count: 10001,
        country_id: _countries.find(c => c.name === localStorage.getItem('country'))?.id
      })
      // console.log({
      //   parent_id: localStorage.getItem('businessLineId'),
      //   page_number: 1,
      //   items_count: 10001,
      //   country_id: _countries.find(c => c.name === localStorage.getItem('country'))?.id
      // })
      // console.log(_categories)
      // console.log({
      //   parent_id: bl.id,
      //   page_number: 1,
      //   items_count: 10002
      // })
      // const _categories2 = await api.getCategories({
      //   parent_id: bl.id,
      //   page_number: 1,
      //   items_count: 10002
      // })
      // console.log(_categories2)
      // const _categories3 = await api.getCategories({
      //   parent_id: localStorage.getItem('businessLineId'),
      //   page_number: 1,
      //   items_count: 10000,
      //   country_id: _countries.find(c => c.name === localStorage.getItem('country'))?.id
      // })
      const _categories3 = await api.getCategories({
        page_number: 1,
        items_count: 10000,
        parent_id: '00000000-0000-0000-0000-000000000000',
        country_id: _countries.find(c => c.name === localStorage.getItem('country'))?.id
      })
      _categories = [
        ..._categories,
        // ..._categories2,
        ..._categories3
        // ..._categories3,
      ]

      // _categories = _categories.filter(c => c.video_exists)

      for(let i = 0; i < _categories.length; i++) {
        let subCategories = await api.getCategories({
          parent_id: _categories[i].id,
          page_number: 1,
          items_count: 1000,
          business_line_id: localStorage.getItem('businessLineId'),
          country_id: _countries.find(c => c.name === localStorage.getItem('country'))?.id

        })
        // const subCategories2 = await api.getCategories({
        //   parent_id: _categories[i].id,
        //   page_number: 1,
        //   items_count: 1000,
        //   country_id: _countries.find(c => c.name === localStorage.getItem('country'))?.id
        // })
        // subCategories = [
        //   ...subCategories,
        //   ...subCategories2          
        // ]

        console.log(subCategories.length)

        subCategories = subCategories.filter(s => s.video_exists)
        console.log(subCategories)
        _categories[i].subCategories = subCategories
      }
      console.log('CATS', _categories.map(c => c.name))
      _categories = _categories.filter(c => c.video_exists || c.subCategories.length > 0)
      setCategories(_categories)
    }

    const [el] = document.getElementsByClassName('simplebar-content-wrapper')
    if(el) {
      if(el.scrollHeight == el?.clientHeight)
        setIsScroll(false)
      else
        setIsScroll(el.scrollHeight + 20 > el?.clientHeight)
    }
  }, [isUser, isAdmin])

  useEffect(() => {
    const _originalRole = localStorage.getItem('originalRole')
    setOriginalRole(_originalRole)
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    const _menus = menus.map((item) => {
      return {
        ...item,
        selected: !item.unselect && (pathname === item.route)
      }
    }).filter((item) => item[role])
    if(_menus?.[0]?.text === "LIVE STREAM") {
      api.searchVideo({
        search: null,
        items_count: 10000,
        page_number: 1
      }).then(data => {
        const found = data.find(v => v.is_stream && v.status == 'stream_started')
        if(found) {
          _menus[0].icon = <CircleIcon fontSize='large' sx={{ color: 'red' }} />
          // menus[0].route = `/video?id=${found.id}`
          setMenuList(_menus)
        }
        else {
          _menus[0].icon = <CircleIcon fontSize='large' sx={{ color: '#024e7b', width: 0 }} />
          // menus[0].route = `/video?id=${found.id}`
          setMenuList(_menus)
        }

      })
    }
    else
      setMenuList(_menus)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, role]);

  const SubMenuElement = ({ text, route, selected }) => (
    <Box sx={{ position: 'relative', mt: 1, ml: -1 }}>
      <RouterLink to={`/`} underline='none' style={{ textDecoration: 'none', width: '100%' }}>
        <Box sx={{ backgroundColor: isAdmin ? (selected ? '#4c4c4c' : '#666666') : (selected ? '#083c5a' : '#05456b'), height: '60px', color: 'white', pl: 0.5, ':hover': { backgroundColor: '#4c4c4c' }, borderTop: isAdmin ? '1px solid #4c4c4c' : '1px solid #005587' }}>
            <Box sx={{ textAlign: 'left', color: '#f3f3f3', width: '100%', height: '60px !important', mt: 2, ml: 6, fontSize: '15px', textDecoration: 'none !important', fontWeight: 300 }}>{text}</Box>
        </Box>
      </RouterLink>
    </Box>
  )

  const isMobileDevice = () => {
    const userAgent = typeof window.navigator === "undefined" ? "" : navigator.userAgent;
    console.log('userAgent ' + userAgent)
    const mobile = Boolean(userAgent.match(
      /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
    ));
    return mobile;
  };


  const changeRole = async (_role, bls = false) => {
    console.log(_role)
    if(_role === 'lead_admin') {
      await api.switchRole({
        user_sid: localStorage.getItem('sid'),
        role: 'lead_admin'
      })
      console.log(_role)
      localStorage.setItem('role', 'lead_admin')
      localStorage.setItem('businessLine', 'Lead Admin')
      // localStorage.setItem('businessLineId', '61cd298f-bde9-4aba-9680-f05e0651d2d9')
      setBusinessLine('Lead Admin')
      // setBusinessLineId('61cd298f-bde9-4aba-9680-f05e0651d2d9')
      setIsAdmin(true)
      setIsApprover(false)
      setIsApproverDCE(false)
      setIsUser(false)
      setIsDataUser(false)
      setIsLiveStreamAdmin(false)
      setIsAdminCoordinator(false)
      setIsAdminCoordinatorDCE(false)
      setRole('lead_admin')
    }
    if(_role === 'approver') {
      await api.switchRole({
        user_sid: localStorage.getItem('sid'),
        role: 'approver'
      })
      localStorage.setItem('role', 'approver')
      localStorage.setItem('businessLine', 'Approver')
      // localStorage.setItem('businessLineId', '61cd298f-bde9-4aba-9680-f05e0651d2d9')
      setBusinessLine('Approver')
      // setBusinessLineId('61cd298f-bde9-4aba-9680-f05e0651d2d9')
      setIsAdmin(false)
      setIsApprover(true)
      setIsApproverDCE(false)
      setIsUser(false)
      setIsDataUser(false)
      setIsLiveStreamAdmin(false)
      setIsAdminCoordinator(false)
      setIsAdminCoordinatorDCE(false)
      setRole('approver')
    }
    if(_role === 'admin_coordinator') {
      await api.switchRole({
        user_sid: localStorage.getItem('sid'),
        role: 'admin_coordinator'
      })
      localStorage.setItem('role', 'admin_coordinator')
      localStorage.setItem('businessLine', 'Admin Coordinator')
      // localStorage.setItem('businessLineId', '61cd298f-bde9-4aba-9680-f05e0651d2d9')
      setBusinessLine('Admin Coordinator')
      // setBusinessLineId('61cd298f-bde9-4aba-9680-f05e0651d2d9')
      setIsAdmin(false)
      setIsApprover(false)
      setIsApproverDCE(false)
      setIsUser(false)
      setIsDataUser(false)
      setIsLiveStreamAdmin(false)
      setIsAdminCoordinator(true)
      setIsAdminCoordinatorDCE(false)
      setRole('admin_coordinator')
    }
    if(_role === 'admin_coordinator_dce') {
      await api.switchRole({
        user_sid: localStorage.getItem('sid'),
        role: 'admin_coordinator_dce'
      })
      localStorage.setItem('role', 'admin_coordinator_dce')
      localStorage.setItem('businessLine', 'Admin Coordinator DCE')
      // localStorage.setItem('businessLineId', '61cd298f-bde9-4aba-9680-f05e0651d2d9')
      setBusinessLine('Admin Coordinator DCE')
      // setBusinessLineId('61cd298f-bde9-4aba-9680-f05e0651d2d9')
      setIsAdmin(false)
      setIsApprover(false)
      setIsApproverDCE(false)
      setIsUser(false)
      setIsDataUser(false)
      setIsLiveStreamAdmin(false)
      setIsAdminCoordinator(false)
      setIsAdminCoordinatorDCE(true)
      setRole('admin_coordinator_dce')
    }
    if(_role === 'approver_dce') {
      await api.switchRole({
        user_sid: localStorage.getItem('sid'),
        role: 'approver_dce'
      })
      localStorage.setItem('role', 'approver_dce')
      localStorage.setItem('businessLine', 'Approver DCE')
      // localStorage.setItem('businessLineId', '61cd298f-bde9-4aba-9680-f05e0651d2d9')
      setBusinessLine('Approver DCE')
      // setBusinessLineId('61cd298f-bde9-4aba-9680-f05e0651d2d9')
      setIsAdmin(false)
      setIsApprover(false)
      setIsApproverDCE(true)
      setIsUser(false)
      setIsDataUser(false)
      setIsLiveStreamAdmin(false)
      setIsAdminCoordinator(false)
      setIsAdminCoordinatorDCE(false)
      setRole('approver_dce')
    }
    if(_role === 'livestream_admin') {
      await api.switchRole({
        user_sid: localStorage.getItem('sid'),
        role: 'livestream_admin'
      })
      localStorage.setItem('role', 'livestream_admin')
      localStorage.setItem('businessLine', 'Live Stream Admin')
      // localStorage.setItem('businessLineId', '61cd298f-bde9-4aba-9680-f05e0651d2d9')
      setBusinessLine('Live Stream Admin')
      // setBusinessLineId('61cd298f-bde9-4aba-9680-f05e0651d2d9')
      setIsAdmin(false)
      setIsApprover(false)
      setIsApproverDCE(false)
      setIsUser(false)
      setIsDataUser(false)
      setIsLiveStreamAdmin(true)
      setIsAdminCoordinator(false)
      setIsAdminCoordinatorDCE(false)
      setRole('livestream_admin')
    }
    if(_role === 'data_user') {
      await api.switchRole({
        user_sid: localStorage.getItem('sid'),
        role: 'data_user'
      })
      localStorage.setItem('role', 'data_user')
      localStorage.setItem('businessLine', 'Data Admin')
      // localStorage.setItem('businessLineId', '61cd298f-bde9-4aba-9680-f05e0651d2d9')
      setBusinessLine('Data Admin')
      // setBusinessLineId('61cd298f-bde9-4aba-9680-f05e0651d2d9')
      setIsAdmin(false)
      setIsApprover(false)
      setIsApproverDCE(false)
      setIsUser(false)
      setIsDataUser(true)
      setIsLiveStreamAdmin(false)
      setIsAdminCoordinator(false)
      setIsAdminCoordinatorDCE(false)
      setRole('data_user')
    }
    if(_role === 'user') {
      await api.switchRole({
        user_sid: localStorage.getItem('sid'),
        role: null
      })
      localStorage.setItem('role', 'user')
      // console.log(localStorage.getItem('businessLineId'))
      // console.log(businessLines)
      let busLines = bls && bls?.length > 0 ? bls : businessLines
      const _businessLine = busLines.find(b => b.id === localStorage.getItem('businessLineId'))
      console.log(_businessLine)
      localStorage.setItem('businessLine', _businessLine?.name)
      // localStorage.setItem('businessLineId', '61cd298f-bde9-4aba-9680-f05e0651d2d9')
      setBusinessLine(_businessLine?.name)
      // setBusinessLineId('61cd298f-bde9-4aba-9680-f05e0651d2d9')
      setIsAdmin(false)
      setIsApprover(false)
      setIsApproverDCE(false)
      setIsUser(true)
      setRole('user')
    }
    navigate('/changerole')
  }


  const MenuElement = ({ icon, text, children, route, selected, last, isRemote }) => {

    const [isOpen, setIsOpen] = useState(false);

    return icon 
    ? (
      <Box sx={{ backgroundColor: !isUser ? (selected ? '#4c4c4c' : '#666666') : (selected ? '#083c5a' : '#024e7b'), height: '60px', color: 'white', pl: 0.5, ':hover': { backgroundColor: !isUser ? '#4c4c4c' : '#083c5a' }, borderTop: !isUser ? '1px solid #4c4c4c' : '1px solid #005587', borderBottom: last ? (!isUser ? '1px solid #4c4c4c' : '1px solid #005587') : 'none' }}>
        <Box onClick={() => { 
          if(isRemote) {
            let anchor = document.createElement('a');
            anchor.href = route;
            anchor.target="_blank";
            anchor.click();
          }
          else 
            navigate(route)
         }} sx={{ cursor: 'pointer' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', color: '#f3f3f3', height: '60px !important', }}>
            <Box sx={{ flex: 0.3, textAlign: 'center', mt: 1.5 }}>{icon}</Box>
            <Box sx={{ flex: 0.7, textAlign: 'left', mt: 2, fontSize: '17px', ml: -1, textDecoration: 'none !important', fontWeight: 600 }}>{text}</Box>
          </Box>
        </Box>
      </Box>
    )
    : (
      <Box sx={{ position: 'relative' }}>
        <Box onClick={() => navigate(route)} sx={{ cursor: 'pointer', width: '100%' }}>
          <Box sx={{ backgroundColor: !isUser ? (selected ? '#4c4c4c' : '#595959') : (selected ? '#083c5a' : '#05456b'), height: '60px', color: 'white', pl: 0.5, ':hover': { backgroundColor: '#4c4c4c' }, borderTop: !isUser ? '1px solid #4c4c4c' : '1px solid #005587' }}>
              <Box sx={{ textAlign: 'left', color: '#f3f3f3', width: '100%', height: '60px !important', mt: 2, ml: 6, fontSize: '15px', textDecoration: 'none !important', fontWeight: 300 }}>{text}</Box>
          </Box>
        </Box>
      </Box>
    )
  }


  const SubCategoryElement = ({ id, name, selected, last, parent_id }) => {

    return (
      <Box sx={{ display: parent_id === hoverCategory ? 'initial' : 'none', position: 'absolute', left: '270px', backgroundColor: !isUser ? (selected ? '#4c4c4c' : '#796056') : (selected ? '#083c5a' : '#05456b'), height: '60px', color: 'white', pl: 0.5, ':hover': { backgroundColor: !isUser ? '#4c4c4c' : '#083c5a' }, borderTop: !isUser ? '1px solid #4c4c4c' : '1px solid #005587', borderBottom: last ? (!isUser ? '1px solid #4c4c4c' : '1px solid #005587') : 'none' }}>
        <RouterLink to={`/categoryvideos?category=${id}&category_name=${escape(name)}`} underline='none' style={{ textDecoration: 'none', width: '100%' }}>
          <Box sx={{ textAlign: 'left', color: '#f3f3f3', width: '100%', height: '60px !important', mt: 2, ml: 6, fontSize: '15px', textDecoration: 'none !important', fontWeight: 300 }}>{name}</Box>
        </RouterLink>
      </Box>
    )
  }

  const CategoryElement = ({ id, name, selected, last, subCategories }) => {

    return (
      <Box sx={{ overflow: 'visible !important' }}>
        <Box onClick={() => navigate(`/categoryvideos?category=${id}&category_name=${escape(name)}`)} sx={{ cursor: 'pointer', width: '100%' }} onMouseEnter={() => setHoverCategory(id)} onMouseLeave={() => setHoverCategory('')}>
          <Box sx={{ backgroundColor: selected ? '#083c5a' : '#05456b', height: '60px', color: 'white', pl: 0.5, ':hover': { backgroundColor: '#083c5a' }, borderTop: '1px solid #005587', borderBottom: last ? '1px solid #005587' : 'none' }}>
              <Box sx={{ textAlign: 'left', color: '#f3f3f3', width: '100%', height: '60px !important', mt: 2, ml: 6, fontSize: '15px', textDecoration: 'none !important', fontWeight: 300 }}>{name}</Box>
          </Box>
        </Box>
          {(hoverCategory === id) && 
           <Box  onMouseLeave={() => setHoverCategory('')} sx={{ overflowY: subCategories.length > 5 ? 'scroll !important' : 'hidden', height: 'auto', maxHeight: '300px', position: 'fixed', display: 'block', left: '300px', zIndex: '10000 !important', backgroundColor: 'transparent !important', width: '300px', marginTop: `calc(-${60 + parseFloat(sidebarScroll.toFixed(2))}px)`}}>
              {subCategories.map((subCategory, index) => (
                <Box onClick={() => {  setHoverCategory(''); navigate(`/categoryvideos?category=${subCategory.id}&category_name=${escape(subCategory.name)}`)}} onMouseEnter={() => setHoverCategory(id)} sx={{ cursor: 'pointer', height: '60px' /*,  marginTop: `${(index-1) * 60}px`*/ }}>
                  <Box sx={{ backgroundColor: selected ? '#083c5a' : '#05456b', height: '60px', color: 'white', pl: 0.5, ':hover': { backgroundColor: '#083c5a' }, borderTop: '1px solid #005587', borderBottom: last ? '1px solid #005587' : 'none' }}>
                      <Box sx={{ textAlign: 'center', color: '#f3f3f3', width: '100%', height: '60px !important', justifyContent: 'center', display: 'flex', alignItems: 'center', fontSize: '15px', textDecoration: 'none !important', fontWeight: 300 }}>{subCategory.name}</Box>
                  </Box>
                </Box>
              ))}
        </Box>}
      </Box>
    )
  }


  const renderContent = (
    <Scrollbar
      sx={{
        height: '100%',
        // overflowY: 'scroll !important',
        overflowX: 'visible',
        width: '100%',
        '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' },
        '& .simplebar-content-wrapper': { overflowX: 'hidden !important' }

      }}
    >
      <Box sx={{ textAlign: 'left', mt: 1, ml: 1.5, display: 'flex', flexDirection: 'row' }}>
        <IconButton onClick={() => setIsOpen(!isOpen)} sx={{ color: 'text.primary' }}>
          <MenuIcon fontSize="inherit" sx={{ color: 'white', fontSize: '55px' }} />
        </IconButton>
        <Box sx={{ color: 'white', mt: 1.9 }}>
          <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>
            {businessLine}
          </Typography>
          <Typography sx={{ fontWeight: 100, fontSize: '14px' }}>
            {country}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', mt: 4 }}>
        {menuList.map((el, index) => (
          <MenuElement {...el} las={(index === menuList.length - 1) && !isUser} />
        ))}
        {isUser && categories.map((el, index) => (
          <>
            <CategoryElement {...el} last={index === categories.length - 1} />
            {/* {el.subCategories.map((e, index) => (
              <SubCategoryElement {...e} last={index === el.subCategories.length - 1} />
            ))} */}
          </>
        ))}
        {!['user', 'lead_admin'].includes(originalRole) &&
          <Box sx={{ mt: 4, ml: 5, mb: 4, position: isScroll ? 'initial' : 'absolute', bottom: 0 }}>
            <Stack direction="row" spacing={0} alignItems="center" sx={{  }}>
              {/* <Typography sx={{ color: 'white' }}>
                {rolesObj[originalRole]}
              </Typography> */}
              <Switch defaultChecked={isOriginalRole} checked={isOriginalRole} onChange={(e) => {
                if(e.target.checked)
                  changeRole(originalRole)
                else
                  changeRole('user')
              }} />
              <Typography sx={{ color: 'white' }}>User / Admin</Typography>
            </Stack>
          </Box>}
        {originalRole === 'lead_admin' && <Box sx={{ mt: 4, ml: 5, mb: 2, position: (isScroll || isAdmin || true) ? 'initial' : 'absolute', bottom: 0 }}>
          <Stack direction="row" spacing={0} alignItems="center" sx={{  }}>
            {/* <Typography sx={{ color: 'white' }}>User</Typography> */}
            <Switch defaultChecked={isAdmin} checked={isAdmin} onChange={() => changeRole('lead_admin')} />
            <Typography sx={{ color: 'white' }}>Lead Admin</Typography>
          </Stack>
          <Stack direction="row" spacing={0} alignItems="center" sx={{  }}>
            {/* <Typography sx={{ color: 'white' }}>User</Typography> */}
            <Switch defaultChecked={isLiveStreamAdmin} checked={isLiveStreamAdmin} onChange={() => changeRole('livestream_admin')} />
            <Typography sx={{ color: 'white' }}>Live Stream Admin</Typography>
          </Stack>
          <Stack direction="row" spacing={0} alignItems="center" sx={{  }}>
            {/* <Typography sx={{ color: 'white' }}>User</Typography> */}
            <Switch defaultChecked={isAdminCoordinator} checked={isAdminCoordinator} onChange={() => changeRole('admin_coordinator')} />
            <Typography sx={{ color: 'white' }}>Admin Coordinator</Typography>
          </Stack>
          <Stack direction="row" spacing={0} alignItems="center" sx={{  }}>
            {/* <Typography sx={{ color: 'white' }}>User</Typography> */}
            <Switch defaultChecked={isAdminCoordinatorDCE} checked={isAdminCoordinatorDCE} onChange={() => changeRole('admin_coordinator_dce')} />
            <Typography sx={{ color: 'white' }}>Admin Coordinator DCE</Typography>
          </Stack>
          <Stack direction="row" spacing={0} alignItems="center" sx={{  }}>
            {/* <Typography sx={{ color: 'white' }}>User</Typography> */}
            <Switch defaultChecked={isDataUser} checked={isDataUser} onChange={() => changeRole('data_user')} />
            <Typography sx={{ color: 'white' }}>Data Admin</Typography>
          </Stack>
          <Stack direction="row" spacing={0} alignItems="center" sx={{  }}>
            <Switch defaultChecked={isApprover} checked={isApprover} onChange={() => changeRole('approver')} />
            <Typography sx={{ color: 'white' }}>Approver</Typography>
          </Stack>
          <Stack direction="row" spacing={0} alignItems="center" sx={{  }}>
            <Switch defaultChecked={isApproverDCE} checked={isApproverDCE} onChange={() => changeRole('approver_dce')} />
            <Typography sx={{ color: 'white' }}>Approver DCE</Typography>
          </Stack>
          <Stack direction="row" spacing={0} alignItems="center" sx={{  }}>
            {/* <Typography sx={{ color: 'white' }}>User</Typography> */}
            <Switch defaultChecked={isUser} checked={isUser} onChange={() => changeRole('user')} />
            <Typography sx={{ color: 'white' }}>User</Typography>
          </Stack>
        </Box>}
      </Box>
    </Scrollbar>
  );

  return (
    <RootStyle id='sidebar' sx={{ overflow: 'visible' }}>
      <MHidden width="lgUp">
        <Drawer
          open={isOpen}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH, overflow: 'visible' }
          }}
          onScroll={(e) => console.log(e)}
        >
          {renderContent}
        </Drawer>
      </MHidden>
      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: { width: DRAWER_WIDTH, backgroundColor: !isUser ? '#6f6f6f' : '#005587', overflow: 'visible', marginTop: '40px !important'   }
          }}
          onScroll={(e) => console.log(e)}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
