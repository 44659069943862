import axios from '../utils/axios';

export const api = {
  // validate: async () => {
  //   const { data } = await axios.get('api/validate');
  //   return data;
  // },
  // getKeys: async (params: { items: number; page: number }) => {
  //   let string = '';
  //   if (params.items && params.page) string = `?items=${params.items}&page=${params.page}`;
  //   const { data } = await axios.get(`api/keys${string}`);
  //   return data;
  // },
  // addKey: async (body: { name: string; allowed_hosts: Array }) => {
  //   await axios.post('api/keys', body);
  //   // console.log(data)
  //   // return data.data || data;
  // },

  viewStream: async(body) => {
    const { data } = await axios.post('StreamView?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', {
      user_sid: localStorage.getItem('sid'),
      ...body
    });
    return data
  },  
  assignVideoApprover: async(body) => {
    const { data } = await axios.post('VideoAssignApprover?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', {
      user_sid: localStorage.getItem('sid'),
      ...body
    });
    return data
  },  
  createVideo: async(body) => {
    const { data } = await axios.post('VideoCreate?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', {
      // user_sid: localStorage.getItem('sid'),
      language_id: "98a4d71e-569a-4326-a48f-52514b029816",
      is_private: false,
      ...body
    });
    return data
  },
  encodeVideo: async(body) => {
    const { data } = await axios.post('VideoEncode?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data;
  },
  getNotApprovedVideos: async (body) => {
    const { data } = await axios.post('VideoNotApproved?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', {
      user_sid: localStorage.getItem('sid'),
      ...body
    })
    return data;
  },
  getDeletingVideos: async (body) => {
    const { data } = await axios.post('VideoGetDeleting?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', {
      user_sid: localStorage.getItem('sid'),
      ...body
    })
    return data;
  },
  getApprovedVideos: async (body) => {
    const { data } = await axios.post('VideoApproved?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', {
      user_sid: localStorage.getItem('sid'),
      ...body
    });
    return data;
  },
  getFeaturedVideos: async (body) => {
    const { data } = await axios.post('VideoFeatured?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data;
  },
  getMyVideos: async (body) => {
    const { data } = await axios.post('VideoMyVideo?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data;
  },
  getVideo: async (body) => {
    const { data } = await axios.post('VideoGetByUrl?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', {
      user_sid: localStorage.getItem('sid'),
      ...body
    })
    return data;
  },
  deleteVideo: async (body) => {
    const { data } = await axios.post('VideoDelete?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', {
      user_sid: localStorage.getItem('sid'),
      ...body
    })
    return data;
  },
  requestToDeleteVideo: async (body) => {
    const { data } = await axios.post('VideoRequestToDelete?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', {
      user_sid: localStorage.getItem('sid'),
      ...body
    })
    return data;
  },
  editVideo: async (body) => {
    const { data } = await axios.post('VideoEdit?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', {
      // user_sid: localStorage.getItem('sid'),
      is_private: false,
      language_id: "98a4d71e-569a-4326-a48f-52514b029816",
      ...body
    })
    return data;
  },
  like: async (body) => {
    const { data } = await axios.post('LikeUnlike?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data;
  },
  getLiked: async (body) => {
    const { data } = await axios.post('VideoLiked?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data;
  },
  approveVideo: async (body) => {
    const { data } = await axios.post('VideoApprove?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data;
  },
  rejectVideo: async (body) => {
    const { data } = await axios.post('VideoReject?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data;
  },
  searchVideo: async (body) => {
    const { data } = await axios.post('VideoSearch?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', {
      user_sid: localStorage.getItem('sid'),
      ...body
    });
    return data;
  },
  getComments: async (body) => {
    const { data }= await axios.post('CommentGetByVideo?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data
  },
  createComment: async (body) => {
    const { data }= await axios.post('CommentCreate?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data
  },
  removeComment: async (body) => {
    const { data }= await axios.post('CommentRemove?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data
  },
  getBusinessLines: async ({ page_number, items_count }) => {
    const { data } = await axios.post('CategoryGet?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', { 
      page_number, 
      items_count,
      user_sid: localStorage.getItem('sid')
    });
    return data;
  },
  getCountryBusinessLines: async (body) => {
    const { data } = await axios.post('CountryBusinessLineGet?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data;
  },
  bindCountryBusinessLine: async (body) => {
    const { data } = await axios.post('CountryBusinessLineBindUnbind?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data;
  },
  createBusinessLine: async (body) => {
    const { data } = await axios.post('CategoryCreate?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', { ...body, parent_id: null });
    return data;
  },
  getCategories: async (body) => {
    const { data } = await axios.post('CategoryGet?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', {
      ...body,
      user_sid: localStorage.getItem('sid')
    });
    return data;
  },
  createCategory: async (body) => {
    const { data } = await axios.post('CategoryCreate?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data;
  },
  editCategory: async (body) => {
    const { data } = await axios.post('CategoryEdit?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data;
  },
  getRole: async (body) => {
    const { data } = await axios.post('UserRoleGet?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data;
  },
  setRole: async (body) => {
    const { data } = await axios.post('UserRoleSet?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data;
  },
  switchRole: async (body) => {
    const { data } = await axios.post('UserRoleSwitchRole?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data;
  },
  createAttachment: async (body) => {
    const { data } = await axios.post('AttachmentCreate?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data;
  },
  deleteAttachment: async (body) => {
    const { data } = await axios.post('AttachmentDelete?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data;
  },
  changeThumbnail: async (body) => {
    const { data } = await axios.post('ThumbnailChange?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data;
  },
  watchVideo: async (body) => {
    const { data } = await axios.post('ViewVideo?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data;
  },
  getTags: async (body) => {
    const { data } = await axios.post('TagGet?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data;
  },
  getCountryCategoryTags: async (body) => {
    const { data } = await axios.post('CountryCategoryTagGet?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data;
  },
  getCategoryTags: async (body) => {
    const { data } = await axios.post('TagGetByCategory?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data;
  },
  getCountryTags: async (body) => {
    const { data } = await axios.post('TagGetByCountry?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data;
  },
  addCountryTags: async (body) => {
    const { data } = await axios.post('CountryTagAdd?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data;
  },
  addCategoryTags: async (body) => {
    const { data } = await axios.post('CategoryTagAdd?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data;
  },
  createTag: async (body) => {
    const { data } = await axios.post('TagUpsert?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data;
  },
  editTag: async (body) => {
    const { data } = await axios.post('TagUpsert?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data;
  },
  attachTags: async (body) => {
    const { data } = await axios.post('VideoTagAdd?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data;
  },
  detachTags: async (body) => {
    const { data } = await axios.post('VideoTagDelete?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data;
  },
  deleteTag: async (body) => {
    const { data } = await axios.post('TagDelete?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data;
  },
  getCountries: async (body) => {
    const { data } = await axios.post('CountryGet?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data;
  },
  createCountry: async (body) => {
    const { data } = await axios.post('CountryUpsert?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data;
  },
  getLanguages: async (body) => {
    const { data } = await axios.post('LanguageGet?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data;
  },
  createLanguage: async (body) => {
    const { data } = await axios.post('LanguageUpsert?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data;
  },
  changeFeatured: async (body) => {
    const { data } = await axios.post('FeaturedUnfeatured?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data;
  },
  getNotifications: async (body) => {
    const { data } = await axios.post('NotificationGet?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data;
  },
  readAllNotifications: async (body) => {
    const { data } = await axios.post('NotificationReadAll?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data;
  },
  getVideoInfo: async (body) => {
    const { data } = await axios.post('VideoStatisticInfo?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data;
  },
  getVideoAudience: async (body) => {
    const { data } = await axios.post('VideoStatisticAudience?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data;
  },
  getVideoTotalViews: async (body) => {
    const { data } = await axios.post('VideoStatisticTotalViews?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data;
  },
  getGroupsUsers: async (body) => {
    const { data } = await axios.post('UsersAndGroups?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data;
  },
  setVideoAccess: async (body) => {
    const { data } = await axios.post('VideoAccessSet?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data;
  },
  removeVideoAccess: async (body) => {
    const { data } = await axios.post('VideoAccessRemove?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data;
  },
  getVideoAccess: async (body) => {
    const { data } = await axios.post('VideoAccessGet?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data;
  },
  getVideoChapters: async (body) => {
    const { data } = await axios.post('VideoChapterGet?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data;
  },
  createVideoChapter: async (body) => {
    const { data } = await axios.post('VideoChapterUpsert?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data;
  },
  deleteVideoChapter: async (body) => {
    const { data } = await axios.post('VideoChapterDelete?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data;
  },
  getStreams: async (body) => {
    const { data } = await axios.post('StreamGet?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data;
  },
  createStream: async (body) => {
    const { data } = await axios.post('StreamCreate?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    });
    return data;
  },
  uploadLogo: async (body) => {
    const { data } = await axios.post('LogoChange?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    });
    return data;
  },
  getSettings: async (body) => {
    const { data } = await axios.post('SiteSettingGet?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data;
  },
  upsertSetting: async (body) => {
    const { data } = await axios.post('SiteSettingUpsert?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data;
  },
  startStream: async (body) => {
    const { data } = await axios.post('StreamStart?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data;
  },
  stopStream: async (body) => {
    const { data } = await axios.post('StreamStop?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data;
  },
  deleteStream: async (body) => {
    const { data } = await axios.post('StreamDelete?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data;
  },
  goLiveStream: async (body) => {
    const { data } = await axios.post('StreamGoLive?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data;
  },
  getStreamUrl: async (body) => {
    const { data } = await axios.post('StreamGetUrl?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data;
  },
  getMessages: async (body) => {
    const { data } = await axios.post('ChatGetMessage?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data;
  },
  sendMessage: async (body) => {
    const { data } = await axios.post('ChatSendMessage?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data;
  },
  subscribeCategory: async (body) => {
    const { data } = await axios.post('CategorySubscribeUnsubscribe?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data;
  },
  getSubscribedCategories: async (body) => {
    const { data } = await axios.post('CategoryGetSubscribed?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data
  },
  changeStreamThumbnail: async (body) => {
    const { data } = await axios.post('StreamThumbnailChange?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data
  },
  changeCategoryThumbnail: async (body) => {
    const { data } = await axios.post('CategoryThumbnailChange?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data
  },
  setPinnedMessage: async (body) => {
    const { data } = await axios.post('ChatSetPinnedMessage?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data
  },
  getUsersWithRole: async (body) => {
    const { data } = await axios.post('UsersWithRoleGet?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data
  },
  createPoll: async (body) => {
    const { data } = await axios.post('PollCreate?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data
  },
  deletePoll: async (body) => {
    const { data } = await axios.post('PollDelete?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data
  },
  getPolls: async (body) => {
    const { data } = await axios.post('PollGet?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data
  },
  finishPoll: async (body) => {
    const { data } = await axios.post('PollFinish?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data
  },
  getNotStartedPolls: async (body) => {
    const { data } = await axios.post('PollGetNotStarted?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data
  },
  startPoll: async (body) => {
    const { data } = await axios.post('PollStart?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data
  },
  votePoll: async (body) => {
    const { data } = await axios.post('PollVote?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data
  },
  createFeedback: async (body) => {
    const { data } = await axios.post('FeedbackCreate?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data
  },
  getFeedback: async (body) => {
    const { data } = await axios.post('FeedbackGet?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data
  },
  getNotStartedFeedback: async (body) => {
    const { data } = await axios.post('FeedbackGetNotStarted?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data
  },
  startFeedback: async (body) => {
    const { data } = await axios.post('FeedbackStart?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data
  },
  voteFeedback: async (body) => {
    const { data } = await axios.post('FeedbackVote?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data
  },
  enableDisableChat: async (body) => {
    const { data } = await axios.post('ChatEnableDisable?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data
  },
  enableDisablePolls: async (body) => {
    const { data } = await axios.post('PollsEnableDisable?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data
  },
  enableDisableFeedback: async (body) => {
    const { data } = await axios.post('FeedbackEnableDisable?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data
  },
  watchLater: async (body) => {
    const { data } = await axios.post('WatchUnwatch?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data
  },
  getWatchLater: async (body) => {
    const { data } = await axios.post('VideoWatchLater?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data
  },
  getCountryLanguage: async (body) => {
    const { data } = await axios.post('CountryLanguageGet?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data
  },
  bindCountryLanguage: async (body) => {
    const { data } = await axios.post('CountryLanguageBindUnbind?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data
  },
  deleteCategory: async (body) => {
    const { data } = await axios.post('CategoryDelete?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data
  },
  toggleHotTopic: async (body) => {
    const { data } = await axios.post('HotTopicToggle?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data
  },
  getHotTopic: async (body) => {
    const { data } = await axios.post('VideoHotTopic?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data
  },
  toggleSlideshow: async (body) => {
    const { data } = await axios.post('SlideshowToggle?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data
  },
  getSlideshow: async (body) => {
    const { data } = await axios.post('VideoSlideshow?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data
  },
  denyDelete: async (body) => {
    const { data } = await axios.post('VideoDenyRequestToDelete?code=mqT9ZRlKWZXZXNjpu40th4LRroisaV0Iu1r9KoG08klVAzFuyCG24A==', body);
    return data
  }
};
