// hooks
import SvgIcon from '@material-ui/core/SvgIcon';
import Person from '@material-ui/icons/AccountCircleRounded';
import useAuth from '../hooks/useAuth';
import { Box } from '@mui/material'
//
import { MAvatar } from './@material-extend';
import { MAvatarProps } from './@material-extend/MAvatar';
import createAvatar from '../utils/createAvatar';
// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }: MAvatarProps) {
  const { user } = useAuth();
  const gmailAvatar = window.localStorage.getItem('gmail_avatar');
  const gravatar = window.localStorage.getItem('gravatar');
  let avatarUrl;
  if (gmailAvatar) avatarUrl = gmailAvatar;
  else if (gravatar) avatarUrl = gravatar;

  const getMaterialIcon = (component: any) => (
    <SvgIcon component={component} sx={{ width: '100%', height: '100%', color: 'lightgrey' }} />
  );
  return avatarUrl ? (
    <MAvatar
      src={avatarUrl}
      alt={user?.displayName}
      // color={user?.photoURL ? 'default' : createAvatar(user?.displayName).color}
      {...other}
    />
  ) : (
    // {/* {createAvatar(user?.displayName).name} */}
    // </MAvatar>
    <Box {...other}>
      {getMaterialIcon(Person)}
    </Box>
  );
}
