import { useEffect, useState, useRef } from 'react';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import { useNavigate, useLocation, Link as RouterLink } from 'react-router-dom';
// material
import { createTheme, makeStyles } from '@material-ui/core/styles';
import { alpha, styled } from '@mui/system';
import { Box, Link, AppBar, Toolbar, IconButton, TextField, InputAdornment, Badge, Button, Typography, MenuItem } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import PublishIcon from '@mui/icons-material/Publish';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
// import SvgIcon from '@mui/material/SvgIcon';
// import Help from '@material-ui/icons/HelpOutline';
// components
import { MHidden } from '../components/@material-extend';
import Searchbar from './Searchbar';
import AccountPopover from './dashboard/AccountPopover';
// import LanguagePopover from './LanguagePopover';
// import ContactsPopover from './ContactsPopover';
// import NotificationsPopover from './NotificationsPopover';
import MenuIcon from '@mui/icons-material/Menu';
// ----------------------------------------------------------------------
import { api } from '../service/';
import MenuPopover from '../components/MenuPopover';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import CircleIcon from '@mui/icons-material/Circle';

import ReactGA from 'react-ga';
const TRACKING_ID = "UA-120137924-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const DRAWER_WIDTH = 300;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;


const MenuElement = ({ name, selected, link, cb }) => {

  const navigate = useNavigate()

  return (
    <Box sx={{ overflow: 'visible !important' }}>
      <Box onClick={() => { navigate(link); cb(false) }} sx={{ cursor: 'pointer', width: '100%' }} >
        <Box sx={{ backgroundColor: selected ? '#083c5a' : '#024e7b', height: '50px', color: 'white', pl: 0.5, ':hover': { backgroundColor: '#083c5a' }, borderBottom: '2px solid #30d5c8', /*borderTop: first ? '1px solid #30d5c8' : 'none'*/ }}>
            <Box sx={{ textAlign: 'left', color: '#f3f3f3', width: '100%', height: '50px !important', pt: 3, pl: 0.5, fontSize: '17px', textDecoration: 'none !important', fontWeight: 300 }}>{name}</Box>
        </Box>
      </Box>
    </Box>
  )
}

const NewMenuElement = ({ icon, text, children, route, selected, last, isRemote, cb }) => {

  const navigate = useNavigate();


  return (
    <Box sx={{ backgroundColor: '#024e7b', height: '60px', color: 'white', ml: -2, ':hover': { backgroundColor: '#083c5a' }, borderTop: '1px solid #005587', borderBottom: last ? '1px solid #005587' : 'none' }}>
      <Box onClick={() => { 
        if(isRemote) {
          let anchor = document.createElement('a');
          anchor.href = route;
          anchor.target="_blank";
          anchor.click();
          cb(false)
        }
        else {
          navigate(route)
          cb(false)
        }
       }} sx={{ cursor: 'pointer' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', color: '#f3f3f3', height: '60px !important', }}>
          <Box sx={{ flex: 0.3, textAlign: 'center', mt: 1.5 }}>{icon}</Box>
          <Box sx={{ flex: 0.7, textAlign: 'left', mt: 2, fontSize: '17px', ml: -3, textDecoration: 'none !important', fontWeight: 600 }}>{text}</Box>
        </Box>
      </Box>
    </Box>
  )
}

const CategoryElement = ({ id, name, selected, last, first, subCategories, cb }) => {

  const navigate = useNavigate()

  return (
    <Box sx={{ overflow: 'visible !important' }}>
      <Box onClick={() => { navigate(`/categoryvideos?category=${id}&category_name=${escape(name)}`); cb(false) }} sx={{ cursor: 'pointer', width: '100%' }} >
        <Box sx={{ backgroundColor: selected ? '#083c5a' : '#024e7b', height: '50px', color: 'white', pl: 0.5, ':hover': { backgroundColor: '#083c5a' }, borderBottom: '2px solid #30d5c8', /*borderTop: first ? '1px solid #30d5c8' : 'none'*/ }}>
            <Box sx={{ textAlign: 'left', color: '#f3f3f3', width: '100%', height: '50px !important', pt: 3, pl: 3, fontSize: '15px', textDecoration: 'none !important', fontWeight: 300 }}>{name}</Box>
        </Box>
      </Box>
    </Box>
  )
}

const NewCategoryElement = ({ id, name, selected, last, first, subCategories, cb }) => {

  const navigate = useNavigate()

  // return (
  //   <Box sx={{ overflow: 'visible !important' }}>
  //     <Box onClick={() => { navigate(`/categoryvideos?category=${id}&category_name=${escape(name)}`); cb(false) }} sx={{ cursor: 'pointer', width: '100%' }} >
  //       <Box sx={{ backgroundColor: selected ? '#083c5a' : '#024e7b', height: '50px', color: 'white', pl: 0.5, ':hover': { backgroundColor: '#083c5a' }, borderBottom: '2px solid #30d5c8', /*borderTop: first ? '1px solid #30d5c8' : 'none'*/ }}>
  //           <Box sx={{ textAlign: 'left', color: '#f3f3f3', width: '100%', height: '50px !important', pt: 3, pl: 3, fontSize: '15px', textDecoration: 'none !important', fontWeight: 300 }}>{name}</Box>
  //       </Box>
  //     </Box>
  //   </Box>
  // )
  return (
    <Box sx={{ overflow: 'visible !important' }}>
      <Box onClick={() => { navigate(`/categoryvideos?category=${id}&category_name=${escape(name)}`); cb(false) }} sx={{ cursor: 'pointer', width: '100%' }}>
        <Box sx={{ backgroundColor: selected ? '#083c5a' : '#05456b', height: '60px', color: 'white', pl: 0, borderTop: '1px solid #005587', borderBottom: last ? '1px solid #005587' : 'none', ':hover': { backgroundColor: '#083c5a' }, }}>
            <Box sx={{ textAlign: 'left', color: '#f3f3f3', width: '100%', height: '60px !important', mt: 2, ml: 6, fontSize: '15px', textDecoration: 'none !important', fontWeight: 300 }}>{name}</Box>
        </Box>
      </Box>
    </Box>
  )
}

const SubCategoryElement = ({ id, name, selected, first, last, cb }) => {

  const navigate = useNavigate()

  return (
    <Box sx={{ overflow: 'visible !important' }}>
      <Box onClick={() => { navigate(`/categoryvideos?category=${id}&category_name=${escape(name)}`); cb(false) }} sx={{ cursor: 'pointer', width: '100%' }} >
        <Box sx={{ backgroundColor: selected ? '#083c5a' : '#024e7b', height: '40px', width: 'calc(100% - 40px)', mx: '20px', textAlign: 'center', color: 'white', pl: 0, ':hover': { backgroundColor: '#083c5a' } }}>
            <Box sx={{ textAlign: 'left', color: '#f3f3f3', height: '40px !important', pt: 2, fontSize: '12px', pl: '20px', textDecoration: 'none !important', fontWeight: 300, borderBottom: last ? 'none' : '2px solid #30d5c8' }}>{name}</Box>
        </Box>
      </Box>
    </Box>
  )
}

const RootStyle = styled(AppBar)(({ theme }) => ({
  marginTop: '40px !important',
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  // backgroundColor: alpha(theme.palette.background.default, 0.72),
  backgroundColor: '#fff !important',
  paddingBottom: '15px !important',
  // marginTop: '30px',
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
    paddingLeft: '40px'
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// const getMaterialIcon = (component: any) => (
//   <SvgIcon component={component} sx={{ width: '40px', height: '40px', color: '#637381' }} />
// );

// ----------------------------------------------------------------------

type DashboardNavbarProps = {
  //onOpenSidebar: VoidFunction;
};

let menus = [
  {
    icon: <CircleIcon fontSize='large' sx={{ color: '#024e7b', width: 0 }} />,
    text: 'LIVE STREAM',
    route: '/livestreams',
  },
  {
    icon: <StarBorderIcon fontSize='large' sx={{ color: 'white' }} />,
    text: 'FEATURED',
    route: '/featured'
  },
  {
    icon: <VideoLibraryIcon fontSize='large' sx={{ color: 'white' }}  />,
    text: 'VIDEO LIBRARY',
    route: '/videolibrary',
  },
]

export default function DashboardNavbar({ role }: DashboardNavbarProps) {
  const { pathname } = useLocation();
  const anchorRef = useRef(null);
  const isMapView =
    pathname.includes('/map/') || pathname.includes('/style/') || pathname.includes('/dataset/');
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState(localStorage.getItem('role') === 'lead_admin')
  const [isUser, setIsUser] = useState(localStorage.getItem('role') === 'user')
  const [isApprover, setIsApprover] = useState(localStorage.getItem('role') === 'approver')
  const [isApproverDCE, setIsApproverDCE] = useState(localStorage.getItem('role') === 'approver_dce')
  const [isDataUser, setIsDataUser] = useState(localStorage.getItem('role') === 'data_user')
  const [isAdminCoordinator, setIsAdminCoordinator] = useState(localStorage.getItem('role') === 'admin_coordinator')
  const [isAdminCoordinatorDCE, setIsAdminCoordinatorDCE] = useState(localStorage.getItem('role') === 'admin_coordinator_dce')
  const [isLiveStreamAdmin, setIsLiveStreamAdmin] = useState(localStorage.getItem('role') === 'livestream_admin')
  const [notifications, setNotifications] = useState([])
  const [notificationsNumber, setNotificationsNumber] = useState('0')
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenMenu, setIsOpenMenu] = useState(false)
  const [mobileDimensions, setMobileDimensions] = useState({})
  const [categories, setCategories] = useState([])
  const [isSmall, setIsSmall] = useState(false)
  const [countries, setCountries] = useState([])
  const [country, setCountry] = useState('')
  const [menuList, setMenuList] = useState([])
  const [logo, setLogo] = useState('')
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const checkIsSmall = () => {
    const { width, height } = getWindowDimensions()
    if(width < 1750)
        setIsSmall(true)
    else
        setIsSmall(false)
  }

  // const [options, setOptions] = useState([]);

  useEffect(async() => {
    
    checkIsSmall()
    window.addEventListener("resize", checkIsSmall);
    // const data = await api.searchVideo({
    //   search: null,
    //   page_number: 1,
    //   items_count: 10000
    // })

    const settings = await api.getSettings({})
    console.log(settings)
    const logoData = settings.find(s => s.name === 'main_logo_url')
    // if(logoData)
    //   setLogo(logoData.value)
    // else
      setLogo('/static/deloitte/widelogo.png')

    const _countries = await api.getCountries({
      page_number: 1,
      items_count: 10000
    })
    setCountries(_countries)
    setCountry(_countries.find(c => c.name === localStorage.getItem('country'))?.id)

    const bls = await api.getBusinessLines({
      page_number: 1,
      items_count: 10000
    })
    const bl = bls.find(b => b.name === `DEFAULT_BUSINESS_LINE`)
    const _categories3 = await api.getCategories({
      parent_id: localStorage.getItem('businessLineId'),
      page_number: 1,
      items_count: 10001,
      country_id: _countries.find(c => c.name === localStorage.getItem('country'))?.id
    })
    let _categories2 = await api.getCategories({
      parent_id: bl.id,
      page_number: 1,
      items_count: 10002
    })
    console.log(_categories2)
    // let _categories = await api.getCategories({
    //   parent_id: localStorage.getItem('businessLineId'),
    //   page_number: 1,
    //   items_count: 10000
    // })

    let _categories = [
      // ..._categories,
      ..._categories3,
      ..._categories2
    ]

    for(let i = 0; i < _categories.length; i++) {
      let _subCategories = await api.getCategories({
        parent_id: _categories[i].id,
        page_number: 1,
        items_count: 10005,
        business_line_id: localStorage.getItem('businessLineId'),
        country_id: _countries.find(c => c.name === localStorage.getItem('country'))?.id
      })
      // const _subCategories2 = await api.getCategories({
      //     parent_id: _categories[i].id,
      //     page_number: 1,
      //     items_count: 10000,
      //     country_id: _countries.find(c => c.name === localStorage.getItem('country'))?.id
      // })
      // _subCategories = [
      //     ..._subCategories,
      //     ..._subCategories2
      // ]
      _subCategories = _subCategories.filter(s => s.video_exists)

      _categories[i].subCategories = _subCategories
    }
    _categories = _categories.filter(c => c.video_exists || c.subCategories.length > 0)
    setCategories(_categories)

    const data = await api.searchVideo({
      search: null,
      items_count: 10000,
      page_number: 1
    })
    const found = data.find(v => v.is_stream && v.status == 'stream_started')
    if(found) {
      menus[0].icon = <CircleIcon fontSize='large' sx={{ color: 'red' }} />
      setMenuList(menus)
    }
    else {
      menus[0].icon = <CircleIcon fontSize='large' sx={{ color: '#024e7b', width: 0 }} />
      // menus[0].route = `/video?id=${found.id}`
      setMenuList(menus)
    }
    // setOptions(data)
    setIsAdmin(localStorage.getItem('role') === 'lead_admin')
    setIsUser(localStorage.getItem('role') === 'user')
    setIsApprover(localStorage.getItem('role') === 'approver')
    setIsApproverDCE(localStorage.getItem('role') === 'approver_dce')
    setIsDataUser(localStorage.getItem('role') === 'data_user')
    setIsLiveStreamAdmin(localStorage.getItem('role') === 'livestream_admin')
    setIsAdminCoordinator(localStorage.getItem('role') === 'admin_coordinator')
    setIsAdminCoordinatorDCE(localStorage.getItem('role') === 'admin_coordinator_dce')
    // localStorage.setItem('sid', '18a249f0-cb4d-4aba-ab41-fb8e4a3f69d4')
    // localStorage.setItem('businessLineId', '61cd298f-bde9-4aba-9680-f05e0651d2d7')

    // 4JqTbVv9E%xziMIH

    // Adam: c883ad87-2526-4926-8499-d10f67fc144b
    // Roman: 315a8d3c-3a62-4eda-8fe6-82f0106611aa
    // Dmitriy: 18a249f0-cb4d-4aba-ab41-fb8e4a3f69d4
    // Tamaz (tamnagy): 4c395e97-8c53-4fde-a63b-7f15cfa6d999
    // Atilla: 96d972bf-264b-4429-b197-7b225cb16a4b
    // Filip (fkochanski@deloittece.com): 592d91bf-eaee-47df-a335-9a4c5e19212f
    // Nadija (nslencakova): b020e85d-d6d6-4d54-9259-68f474d680fd
    // Erika (esanderova): 14c83866-9fda-43c1-b0a5-68d7e93db074 - Czech Republic - Tax & Legal
    // Eliska (ehlinkova): 67949746-7a8b-4803-9b3d-7fe309a66a4c - Czech Republic - Audit & Assurance
    // Barbora (bgruntova): 6f673934-97ae-4e19-8ce0-2ab80ec68eea - Czech Republic - Audit & Assurance
    // Martin (msmetana): f611858a-451e-4fb7-a982-352dbea2799c - Czech Republic - Audit & Assurance
    // Furlepa (mfurlepa): 4153388f-d2a0-4b32-8ccc-9ef814ada8ac - Poland - Audit & Assurance
    // Milena (mtapsikova): 749eb78f-579f-4619-ad72-20ae54e21dd3 - Czech Republic - Enabling Services

    // Audit & Assurance: c6dc6f08-f196-43fe-9eeb-90cbe6260589
    // Consulting: 61cd298f-bde9-4aba-9680-f05e0651d2d7
    // Enabling Services: 432a5f92-294a-4cb8-88f2-cccfc7d5ca12
    // Tax & Legal: 6f7facb2-ac59-4372-ae08-3328845a4ebd

    // prod:
    // Audit & Assurance: a314513f-6142-4544-8337-c1d5f0a42346
    // Consulting: 980bbc72-feac-4b9f-a60b-f7b7f478025f
    // Tax & Legal: 7788c903-5c51-4c83-92f0-6462fe54493f
    // Enabling Services: 
  }, [])

  useEffect(async() => {
    const data = await api.getRole({
      user_sid: localStorage.getItem('sid')
    })
    const originalRole = data[0]?.original_role
    localStorage.setItem('originalRole', originalRole)
    const notifies = await api.getNotifications({
      user_sid: localStorage.getItem('sid'),
      read: false,
      page_number: 1,
      items_count: 10000
    })
    setNotifications(notifies)
    setNotificationsNumber(`${Math.min(99, notifies.length)}`)
    const { width, height } = getWindowDimensions()
    console.log({ width, height })
    setMobileDimensions({width, height})
    function checkRole() {
      setIsAdmin(localStorage.getItem('role') === 'lead_admin')
      setIsUser(localStorage.getItem('role') === 'user')
      setIsApprover(localStorage.getItem('role') === 'approver')
      setIsApproverDCE(localStorage.getItem('role') === 'approver_dce')
      setIsDataUser(localStorage.getItem('role') === 'data_user')
      setIsLiveStreamAdmin(localStorage.getItem('role') === 'livestream_admin')
      setIsAdminCoordinator(localStorage.getItem('role') === 'admin_coordinator')
      setIsAdminCoordinatorDCE(localStorage.getItem('role') === 'admin_coordinator_dce')
      console.log(localStorage.getItem('role'))
    }
    window.addEventListener('storage', checkRole)
    ReactGA.pageview(pathname)
    return () => {
      window.removeEventListener('storage', checkRole)
    }
  }, [pathname])

  const [input, setInput] = useState('');

  const goToSearch = () => {
    setInput('')
    window.location.href = `/search?s=${input}`
    setIsOpenMenu(false)
  }

  const openModal = async() => {
    setIsOpen(true)
    if(notifications.length > 0)
      await api.readAllNotifications({
        user_sid: localStorage.getItem('sid')
      })
  }

  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return { height, width }
  };

  const useMenuItemStyles = makeStyles(theme => ({
    menuItem: {
      // width: '600px',
      // maxWidth: '600px !important',
      // overflow: 'hidden',
      // textOverflow: 'ellipsis',
      // whiteSpace: 'pre',
      // display: 'block'
      maxWidth: '800px',
      whiteSpace: 'nowrap',
      display: 'block',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    }
  }));

  const classes = useMenuItemStyles()

  return (
    <RootStyle id='navbar' sx={{ zIndex: 10000, backgroundColor: isUser ? 'inherit' : '#86bc25 !important', height: '100px !important' }}>
      {/* <ToolbarStyle> */}
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <MHidden width="lgUp">
          {/* <IconButton onClick={onOpenSidebar} sx={{ mr: 1, p: [0, 0, 1], color: 'text.primary' }}>
            <Icon icon={menu2Fill} />
          </IconButton> */}
        </MHidden>
        <Box sx={{ display: 'flex', flexDirection: 'row', mt: 1, justifyContent: 'end' }}>
          {isAdmin && <Box sx={{ width: '90%', mt: 1 }}>
              <Typography variant="h3" sx={{ color: 'white' }}>
                DeloitteTUBE - Admin panel
              </Typography>
            </Box>}
          {isApprover && <Box sx={{ width: '90%', mt: 1 }}>
            <Typography variant="h3" sx={{ color: 'white' }}>
              DeloitteTUBE - Approver panel
            </Typography>
          </Box>}
          {isApproverDCE && <Box sx={{ width: '90%', mt: 1 }}>
            <Typography variant="h3" sx={{ color: 'white' }}>
              DeloitteTUBE - Approver DCE panel
            </Typography>
          </Box>}
          {isLiveStreamAdmin && <Box sx={{ width: '90%', mt: 1 }}>
            <Typography variant="h3" sx={{ color: 'white' }}>
              DeloitteTUBE - Live Stream Admin panel
            </Typography>
          </Box>}
          {isDataUser && <Box sx={{ width: '90%', mt: 1 }}>
            <Typography variant="h3" sx={{ color: 'white' }}>
              DeloitteTUBE - Data Admin panel
            </Typography>
          </Box>}
          {isAdminCoordinator && <Box sx={{ width: '90%', mt: 1 }}>
            <Typography variant="h3" sx={{ color: 'white' }}>
              DeloitteTUBE - Admin Coordinator panel
            </Typography>
          </Box>}
          {isAdminCoordinatorDCE && <Box sx={{ width: '90%', mt: 1 }}>
            <Typography variant="h3" sx={{ color: 'white' }}>
              DeloitteTUBE - Admin Coordinator DCE panel
            </Typography>
          </Box>}
          {isUser && <Box
          sx={{
            flex: [0.8, 'none', 'none'],
            width: ['198px', '316px', '316px'],
            height: ['40px','56px', '56px'], 
            // width: 'auto',
            // height: 'auto',
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'left',
            ml: [3, 0, 0],
            mt: [0, 1]
          }}>
              <Box 
              component="img"
              src={logo}
              sx={{
                mt: 0,
                width: 'auto',
                height: '100%',
                maxWidth: ['198px', '316px', '316px'],
                maxHeight: ['40px','56px', '56px'],
                cursor: 'pointer'
              }}
              onClick={() => { navigate('/');     setIsOpenMenu(false)                      }          }
            />
          </Box>}
          {/* <Searchbar /> */}
          {isUser && <TextField 
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => goToSearch()} sx={{ position: 'absolute', ml: -5 }}>
                    <SearchIcon sx={{ fontSize: '40px', strokeWidth: '2px' }} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            sx={{
              display: ['none', 'inline-flex', 'inline-flex'],
              width: {
                sm: '49%',
                md: '49%',
                lg: '49%',
                xl: '70%'
              },
              maxWidth: '70%',
              minWidth: {
                md: '300px'
              },
              ml: 3,
              mt: 1
            }}
            value={input}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                goToSearch();
              }
            }}
            onChange={({ target }) => setInput(target.value)} 
          />}
          {isUser &&
          <IconButton sx={{ display: ['initial', 'none', 'none'], mt: -1.2, mr: 0.5, ml: 0 }} onClick={() => { navigate('/upload'); setIsOpenMenu(false)}}>
            <img src="/static/upload.png" style={{ width: '35px', opacity: .5 }} />
          </IconButton>}
          {isUser && 
          <IconButton onClick={() => goToSearch()} sx={{ display: ['initial', 'none', 'none'], ml: [-3, 0, 0], mt: -0.5, mr: [-4, -2, -2], flex: 0.2 }}>
            <SearchIcon sx={{ fontSize: '37px', strokeWidth: '2px' }} />
          </IconButton>}          
          {isUser && <IconButton sx={{ ml: 2, mr: -1, display: ['none', 'initial', 'initial'], ':hover': { backgroundColor: 'transparent' } }} onClick={openModal} ref={anchorRef}>
            <Badge color="primary" anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
                {/* <NotificationsNoneIcon sx={{ fontSize: '45px' }} /> */}
                <img src={`/static/notify_bell/bell_notify_${notificationsNumber}.png`} style={{ width: '42px' }} />
            </Badge>
          </IconButton>}
          <MenuPopover
            open={isOpen}
            onClose={() => {
              setIsOpen(false)
              setNotificationsNumber('0')
            }}
            anchorEl={anchorRef.current}
            sx={{ width: 'auto', p: 1, maxWidth: '800px' }}
          >
            {notifications.length > 0
            ? notifications.map((option) => (

              <MenuItem
                className={classes.menuItem}
                // key={option.label}
                to={`/video?id=${option.video_id}`}
                component={RouterLink}
                // onClick={handleClose}
                sx={{ typography: 'body2', py: 1, px: 2.5 }}
                // onClick={() => navigate()}
              >
                - {option.message}

              </MenuItem>
            ))
            : <Typography sx={{ textAlign: 'center', p: 3 }}>
              There are no notifications
            </Typography>}
          </MenuPopover>
          <AccountPopover sx={{ flex: [0.2, 'none', 'none'] }} />
          {isUser && <Box onClick={() => { navigate("/upload"); setIsOpenMenu(false); }} sx={{ cursor: 'pointer', display: ['none', 'initial', 'initial'], ml: 5 }}>
            <Button variant="contained" sx={{ fontSize: ['10px', '12px', isSmall ? '12px' : '15px'], height: '50px', fontWeight: '400', textTransform: 'none', px: 3, ml: isSmall ? 0 : 1, mt: 1, py: 0, mr: isSmall ? 2 : 4, whiteSpace: 'nowrap', width: { lg: isSmall ? '70%' : '105%', xl: '70%' } }}>
              Add video
              {/* <PublishIcon sx={{ fontSize: '25px', ml: 0 }} /> */}
              <img src="/static/upload.png" style={{ float: 'right', width: isSmall ? '20px' : '25px', marginLeft: '5px',  filter: 'brightness(0) invert(1)' }} />
            </Button>
          </Box>}
          <Box sx={{ flexGrow: 1, display: ['none', 'initial', 'initial'] }} />
        </Box>
        {isUser && <Box sx={{ display: ['initial', 'none', 'none'], zIndex: 1, position: 'relative', width: '100%', height: '50px', backgroundColor: '#024e7b' }}>
          <Typography sx={{ mt: 1.5, ml: 3, fontWeight: 500 }}>
            {localStorage.getItem('businessLine')}
          </Typography>
          <IconButton sx={{ float: 'right', color: 'white', position: 'absolute', right: 10, top: 5 }} onClick={() => setIsOpenMenu(!isOpenMenu)}>
              <MenuIcon />
          </IconButton>
        </Box>}
      </Box>

      {isOpenMenu && (
        <Box 
          sx={{ 
            // position: 'absolute', 
            // marginTop: '111px',
            // minHeight: `${mobileDimensions.height - 60 - 110}px`, 
            // width: `${mobileDimensions.width}px`, 
            position: 'fixed', 
            top: '111px',
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: '#024e7b', 
            opacity: 1, 
            color: 'white', 
            zIndex: '10000 !important', 
            overflowY: 'auto',
            overflowX: 'hidden',
            paddingBottom: '60px',
            minHeight: `${viewportHeight - 111}px`, // Dynamically set the height
            overscrollBehavior: 'none'
            // minHeight: '100vh',
            // height: 'auto'
          }}>
          <Box 
            sx={{ 
              backgroundColor: '#024e7b', 
              position: 'relative', 
              zIndex: '10000 !important', 
              opacity: 0.95, 
              mx: '0px', 
              // overflowY: 'scroll',
              // height: 'auto', 
              minHeight: '100%'
            }}>
            {/* <NewMenuElement name="Video Library" link="/videolibrary" cb={setIsOpenMenu} /> */}
            {menuList.map((el, index) => (
              <NewMenuElement {...el} las={(index === menuList.length - 1) && !isUser} cb={setIsOpenMenu} />
            ))}
            {categories.map((el, index) => (
              <>
                <NewCategoryElement {...el} last={index === categories.length - 1} first={index === 0} cb={setIsOpenMenu} />
                {/* {el?.subCategories.map((e, sIndex) => (
                  <SubCategoryElement {...e} last={(sIndex === el.subCategories.length - 1) && (index === categories.length - 1)} cb={setIsOpenMenu} />
                ))} */}
              </>
            ))}
          </Box>
        </Box>)}
      {/* </ToolbarStyle> */}
    </RootStyle>
  );
}
