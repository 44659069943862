import axios from 'axios';
import { backendHost } from '../config';

// ----------------------------------------------------------------------
export interface IResponse<T> {
  data: Array<T>;
}

let axiosInstance = axios.create({
  baseURL: backendHost,
  withCredentials: true
});

axiosInstance.defaults.headers.common["Authorization"] = localStorage.getItem('token');
axiosInstance.defaults.headers.common["X-XSS-Protection"] = "1; mode=block";
axiosInstance.defaults.headers.common["Strict-Transport-Security"] = "max-age=65540 ; includeSubDomains";
axiosInstance.defaults.headers.common["Cache-Control"] = 'private; no-store; no-cache="Set-Cookie"';


axiosInstance.interceptors.response.use(
  (response) => {
    response.headers['X-XSS-Protection'] = '1; mode=block'
    response.headers['Cache-Control'] = 'private; no-store; no-cache="Set-Cookie"'
    return response
  },
  (error) => {
    console.log(error.response);
    console.log(window.localStorage.getItem('isLoggedIn'));
    if (error.response.status === 401 && window.localStorage.getItem('isLoggedIn')) {
      window.localStorage.clear();
      window.location.href = '/login';
      return;
    }
    throw error.response.data;
    // Promise.reject((error.response && error.response.data) || 'Something went wrong');
  }
);

export const setAuthToken = (token) => {
  axiosInstance.defaults.headers.common["Authorization"] = token;
}

export default axiosInstance;
