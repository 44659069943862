import { useState, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useLocation, Navigate } from 'react-router-dom';
// routes
import { useSnackbar } from 'notistack';
import { IconButton, Button } from '@mui/material';
import { Close as IconClose } from '@mui/icons-material';
import Router from './routes';
// theme
import ThemeConfig from './theme';
// hooks
import useAuth from './hooks/useAuth';
// components
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import LoadingScreen from './components/LoadingScreen';
// import GoogleAnalytics from './components/GoogleAnalytics';
import NotistackProvider from './components/NotistackProvider';
import ThemePrimaryColor from './components/ThemePrimaryColor';
// material
import { config } from './_config';
import { PublicClientApplication } from '@azure/msal-browser'
import { api } from './service';
import { setAuthToken } from './utils/axios';
import ReactGA from 'react-ga';
import detectZoom from 'detect-zoom';
// ----------------------------------------------------------------------


const TRACKING_ID = "UA-120137924-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);



const pageViewsTracking = (props) => {
  const pathname = props.match.path

  let pageView;
  if(pathname === "*")
    pageView = '/not-found'
  else
    pageView = pathname
  
  ReactGA.pageview(pageView)
}

function SnackbarCloseButton({ snackbarKey }) {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton onClick={() => closeSnackbar(snackbarKey)}>
      <IconClose />
    </IconButton>
  );
}

export default function App() {
  const { pathname } = useLocation();
  // const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
  const [_role, setRole] = useState(null)
  console.log(pathname);
  if (pathname[pathname.length - 1] === '/' && pathname.length !== 1)
    window.location.href = pathname.slice(0, -1);
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false
      }
    }
  });

  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return { height, width }
  };


  const handleZoom = () => {
    const { width, height } = getWindowDimensions()
    if(width < 1300 && width > 500) {
      document.body.style.zoom = '80%'
      // document.body.style.scale = 'scale(0.8)'
      // var scale = 'scale(0.9)';
      // document.body.style.webkitTransform =  scale;    // Chrome, Opera, Safari
      //  document.body.style.msTransform =   scale;       // IE 9
      //  document.body.style.transform = scale;
      // document.body.style.scale = 'scale(0.8)'
    }
    else {
      // var scale = '';
      document.body.style.zoom = '100%'
      // document.body.style.webkitTransform =  scale;    // Chrome, Opera, Safari
      // document.body.style.msTransform =   scale;       // IE 9
      // document.body.style.transform = scale;

    }

    var browserZoomLevel = Math.round(window.devicePixelRatio * 100);
    console.log(browserZoomLevel)
    console.log(detectZoom.device())
    // if(browserZoomLevel === 300) {
    //   document.body.style.zoom = '80%'
    //   console.log(document.body.style.zoom)
    // }
    // else if(browserZoomLevel > 200) {
    //   const l = browserZoomLevel / 200
    //   console.log(`${1/l*100}%`)
    //   document.body.style.zoom = `${1/l*100}%`
    //   console.log(document.body.style.zoom)
    // }
    // if (detectZoom.device() * 100 !== 100) {
    //   console.log('your zoom is not 100')
    // } else {
    //   console.log('your zoom is 100')
    // }   
  }  

  // useEffect(() => {
  //   const initialValue = document.body.style.zoom;
  //   var browserZoomLevel = Math.round(window.devicePixelRatio * 100);
  //   if(browserZoomLevel === 300) {
  //     document.body.style.zoom = '80%'
  //     console.log(document.body.style.zoom)
  //   }
  //   else {
  //     const l = browserZoomLevel / 200
  //     document.body.style.zoom = `${1/l*100}%`
  //   }

  //   // Change zoom level on mount
  //   // document.body.style.zoom = "150%";

  //   return () => {
  //     // Restore default value
  //     document.body.style.zoom = initialValue;
  //   };
  // }, []);

  useEffect(async () => {
    handleZoom();
    window.addEventListener('resize', handleZoom);
    const isAuthenticated = localStorage.getItem('isAuthenticated')
    // console.log(isAuthenticated)
    setIsAuth(isAuthenticated == 'true')
    if(!isAuthenticated || isAuthenticated == 'false')
      await login()
  }, [])

  const publicClientApplication = new PublicClientApplication({
    auth: {
      clientId: config.appId,
      redirectUri: config.redirectUri,
      authority: config.authority
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: false
    }
  })

  const login = async () => {
    let accountId = ""
    try {
      // await publicClientApplication.loginPopup({
      //   scopes: config.scopes,
      //   prompt: 'select_account'
      // })
      // console.log("1")
      // const accountId = '18a249f0-cb4d-4aba-ab41-fb8e4a3f69d4'
      // localStorage.setItem('businessLine', 'Consulting')
      // localStorage.setItem('businessLineId', '61cd298f-bde9-4aba-9680-f05e0651d2d7')
      // localStorage.setItem('country', 'Czech Republic')
      // localStorage.setItem('role', 'lead_admin')
      // setRole('user')
      // localStorage.setItem('sid', accountId)
      // localStorage.setItem('name', 'Dmitriy Kostenko')
      // localStorage.setItem('email', 'dmkostenko@deloittece.com')
      // localStorage.setItem('isAuthenticated', 'true')
      // setIsAuth(true)

      publicClientApplication.handleRedirectPromise()
        .then(async (response) => {
          console.log("2")
          console.log(response)
          let homeAccountId = ""
          let name = ""
          let email = ""  
          if (response !== null) {
            console.log("3")
              accountId = response.account.localAccountId;
              homeAccountId = response.account?.homeAccountId;
              name = response.account.name;
              email = response.account.username;
              // Display signed-in user content, call API, etc.
              // localStorage.setItem('isAuthenticated', 'true')
              // setIsAuth(true)
              localStorage.setItem('token', response.idToken)
              setAuthToken(response.idToken)
          } else {
            console.log("4")
              // In case multiple accounts exist, you can select
              const currentAccounts = publicClientApplication.getAllAccounts();
              console.log("5")

              if (currentAccounts.length === 0) {
                  // no accounts signed-in, attempt to sign a user in
                  publicClientApplication.loginRedirect({
                    scopes: config.scopes
                  });
                  console.log("6")

              } else if (currentAccounts.length > 1) {
                  // Add choose account code here
              } else if (currentAccounts.length === 1) {
                  accountId = currentAccounts[0].localAccountId;
                  homeAccountId = currentAccounts[0].homeAccountId;
                  name = currentAccounts[0].name;
                  email = currentAccounts[0].username;
              }
          }
          if(name && email) {
            console.log("7")
            // accountId = '315a8d3c-3a62-4eda-8fe6-82f0106611aa'
            const businessLines = await api.getBusinessLines({
              page_number: 1,
              items_count: 10000
            })
            console.log("8")
            // localStorage.setItem('businessLine', 'Audit & Assurance')
            // localStorage.setItem('businessLineId', '61cd298f-bde9-4aba-9680-f05e0651d2d9')
            // localStorage.setItem('role', 'user')
            // setRole('user')
            // localStorage.setItem('sid', accountId)
            // localStorage.setItem('name', name)
            // localStorage.setItem('email', email)
            // localStorage.setItem('isAuthenticated', 'true')
            // setIsAuth(true)
            api.getRole({
              mail: email
              // mail: 'rstulik@deloittece.com'
            }).then(async (data) => {
              console.log("9")
              if(data && data[0] && data[0]?.role && data[0]?.original_role) {
                console.log("10")
                console.log(data)
                const role = data[0]?.role
                const originalRole = data[0]?.original_role
                const department = data[0]?.user_department
                const country = data[0]?.user_country
                localStorage.setItem('country', country)
                // localStorage.setItem('country', 'Czech Republic')
                console.log(businessLines.find(b => b.name === department)?.id)
                localStorage.setItem('businessLineId', businessLines.find(b => b.name === department)?.id)
                if(role === 'user') {
                  localStorage.setItem('businessLine', department)
                }
                else if(role === 'approver') {
                  localStorage.setItem('businessLine', 'Approver')
                  // localStorage.setItem('businessLineId', '61cd298f-bde9-4aba-9680-f05e0651d2d9')
                }
                else if(role === 'lead_admin') {
                  localStorage.setItem('businessLine', 'Lead Admin')
                  // localStorage.setItem('businessLineId', '61cd298f-bde9-4aba-9680-f05e0651d2d9')
                }
                else if(role === 'admin_coordinator') {
                  localStorage.setItem('businessLine', 'Admin Coordinator')
                  // localStorage.setItem('businessLineId', '61cd298f-bde9-4aba-9680-f05e0651d2d9')
                }
                else if(role === 'livestream_admin') {
                  localStorage.setItem('businessLine', 'Live Stream Admin')
                  // localStorage.setItem('businessLineId', '61cd298f-bde9-4aba-9680-f05e0651d2d9')
                }
                else if(role === 'data_user') {
                  localStorage.setItem('businessLine', 'Data Admin')
                  // localStorage.setItem('businessLineId', '61cd298f-bde9-4aba-9680-f05e0651d2d9')
                }
                else {
                  localStorage.setItem('businessLine', 'Lead Admin')
                  // localStorage.setItem('businessLineId', '61cd298f-bde9-4aba-9680-f05e0651d2d9')
                }
                localStorage.setItem('role', role)
                localStorage.setItem('originalRole', originalRole)
                setRole(role)
                localStorage.setItem('sid', accountId)
                localStorage.setItem('homeAccountId', homeAccountId)
                localStorage.setItem('name', name)
                localStorage.setItem('email', email)
                localStorage.setItem('isAuthenticated', 'true')
                setIsAuth(true)
                // const logoutRequest = {
                //   account: publicClientApplication.getAccountByHomeId(accountId)
                // }            
                // await publicClientApplication.logoutRedirect(logoutRequest);
              }
              else {
                api.setRole({
                  user_sid: accountId,
                  mail: email,
                  role: 'user'
                }).then(() => {
                  localStorage.setItem('country', data?.[0]?.user_country)
                  localStorage.setItem('businessLine', data?.[0]?.user_department)
                  localStorage.setItem('businessLineId', businessLines.find(b => b.name === data?.[0]?.user_department)?.id)
                  localStorage.setItem('role', 'user')
                  setRole('user')
                  localStorage.setItem('sid', accountId)
                  localStorage.setItem('homeAccountId', homeAccountId)
                  localStorage.setItem('name', name)
                  localStorage.setItem('email', email)
                  localStorage.setItem('isAuthenticated', 'true')
                  setIsAuth(true)
                })
              }
            })
            .catch(async (err) => {
              console.log("11")
              console.log(err)
              const logoutRequest = {
                account: publicClientApplication.getAccountByHomeId(accountId)
              }
              localStorage.setItem('isAuthenticated', 'false')
              setIsAuth(false)
              localStorage.clear()
              await publicClientApplication.logoutRedirect(logoutRequest);
              // window.location.reload()
            })
          }
        })
        .catch(async (err) => {
          console.log("12")
          console.log(err)
          const logoutRequest = {
            account: publicClientApplication.getAccountByHomeId(accountId)
          }
          localStorage.setItem('isAuthenticated', 'false')
          setIsAuth(false)
          localStorage.clear()
          await publicClientApplication.logoutRedirect(logoutRequest);
          // window.location.reload()
        });
    }
    catch(err) {
      console.log("13")
      console.log(err)
      const logoutRequest = {
        account: publicClientApplication.getAccountByHomeId(accountId)
      }
      localStorage.setItem('isAuthenticated', 'false')
      setIsAuth(false)
      localStorage.clear()
      await publicClientApplication.logoutRedirect(logoutRequest);
      // window.location.reload()
    }
  }

  // return (
  //   <html>
  //     <head>
  //       <title>TITLE!111</title>
  //     </head>
  //     <body>
  //       <div>
  //         123123
  //       </div>
  //     </body>
  //   </html>
  // )
  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <RtlLayout>
          <NotistackProvider
            autoHideDuration={2000}
            action={(snackbarKey) => <SnackbarCloseButton snackbarKey={snackbarKey} />}
          >
            {isAuth ?
            <QueryClientProvider client={queryClient}>
              {/* <Settings /> */}
              <ScrollToTop />
              {/* <GoogleAnalytics /> */}
              <Router role={_role} />
              {/* {isInitialized ? <Router /> : <LoadingScreen />} */}
            </QueryClientProvider>
            : <></>}
          </NotistackProvider>
        </RtlLayout>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}
