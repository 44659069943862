import { useState, ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// hooks
// import useAuth from '../hooks/useAuth';
// pages
// import Login from '../pages/authentication/Login';
// import { api } from '../service';
// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  //   const { isAuthenticated, logout } = useAuth();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);
  //   if (!isAuthenticated) {
  //     if (pathname !== requestedLocation) {
  //       setRequestedLocation(pathname);
  //     }
  //     return <Login />;
  //   }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }
  //   if (isAuthenticated) {
  //     api.validate().then((data) => {
  //       if (data.deleted) {
  //         logout('?dashboard_access=blocked');
  //       }
  //     });
  //     const redirectPath = window.localStorage.getItem('redirect_path');
  //     const isRedirected = window.localStorage.getItem('isRedirected');
  //     console.log(isRedirected);
  //     if (redirectPath && redirectPath !== '' && !isRedirected) {
  //       window.localStorage.setItem('isRedirected', 'true');
  //       window.location.href = `/dashboard${redirectPath}`;
  //     }
  //     if (redirectPath && redirectPath !== '' && isRedirected) {
  //       const email = window.localStorage.getItem('email');
  //       api.removeRedirect(email);
  //       window.localStorage.setItem('redirect_path', '');
  //     }
  //   }
  return <>{children}</>;
}
