import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import Layout from '../layouts/index';
import AuthGuard from '../guards/AuthGuard';

import LoadingScreen from '../components/LoadingScreen';

const Loadable = (Component: any) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};


export default function Router() {
  const role = localStorage.getItem('role')
  const isUser = role === 'user'
  const isApprover = (role === 'approver' || role === 'approver_dce')
  const isAdmin = role === 'lead_admin'
  const isAdminCoordinator = (role === 'admin_coordinator' || role === 'admin_coordinator_dce')
  const isDataUser = role === 'data_user'
  const isLiveStreamAdmin = role === 'livestream_admin'
  

  return useRoutes([
    {
      path: '',
      element: (
        <AuthGuard>
          <Layout />
        </AuthGuard>
      ),
      children: [
        {
          path: '',
          element: 
            (isAdmin && <Posts />) ||
            (isUser && <MainPage />) ||
            (isApprover && <Approve />) ||
            (isLiveStreamAdmin && <LiveStreams />) ||
            (isAdminCoordinator && <Posts />) ||
            (isDataUser && <Statistics />)
        },
        (isAdminCoordinator || isAdmin) && {
          path: '/uploaded',
          element: <Approve />
        },
        (isAdminCoordinator || isAdmin) && {
          path: '/deleted',
          element: <Delete />
        },
        {
          path: '/videolibrary',
          element: <VideoLibrary />
        },
        {
          path: '/upload',
          // element: <Upload />
          element: <UploadBanner />
        },
        isUser && {
          path: '/uservideos',
          element: <UserVideos />
        },
        (isAdminCoordinator || isAdmin) && {
          path: '/categories',
          element: <Categories />
        },
        isAdmin && {
          path: '/businesslines',
          element: <BusinessLines />
        },
        (isAdminCoordinator || isAdmin) && {
          path: '/tags',
          element: <Tags />
        },
        isAdmin && {
          path: '/countries',
          element: <Countries />
        },
        isAdmin && {
          path: '/hottopics',
          element: <HotTopics />
        },
        isAdmin && {
          path: '/slideshow',
          element: <Slideshow />
        },
        (isAdminCoordinator || isAdmin || isLiveStreamAdmin) && {
          path: '/statistics',
          element: <Statistics />
        },
        (isAdminCoordinator || isAdmin || isDataUser || isLiveStreamAdmin) && {
          path: '/statistics/detail',
          element: <StatisticsDetail />
        },
        (isAdminCoordinator || isAdmin || isApprover || isUser) && {
          path: '/approve/detail',
          element: isUser ? <ApproveRedirect/> : <ApproveDetail />
        },
        {
          path: '/video',
          element: <Video />
        },
        {
          path: '/myvideo',
          element: <Video isMyVideo={true} />
        },
        {
          path: '/video/edit',
          element: <VideoEdit isEdit={true} />
        },
        {
          path: '/myvideo/edit',
          element: <VideoEdit isEdit={true} isMyVideo={true} />
        },
        // isApprover && {
        //   path: '/',
        //   element: <Approve />
        // },
        isUser && {
          path: '/myvideos',
          element: <MyVideos />
        },
        isUser && {
          path: '/likedvideos',
          element: <LikedVideos />
        },
        isUser && {
          path: '/watchlater',
          element: <WatchLaterVideos />
        },
        {
          path: '/logout',
          element: <Logout />
        },
        {
          path: '/azure-logout',
          element: <Logout />
        },
        {
          path: '/reply',
          element: <Reply />
        },
        {
          path: '/login',
          element: <Reply />
        },
        !isApprover && {
          path: '/categoryvideos',
          element: <CategoryVideos />
        },
        {
          path: '/search',
          element: <Search />
        },
        {
          path: '/featured',
          element: isAdmin ? <FeaturedAdmin /> : <Featured />
        },
        (isAdmin || isUser) && {
          path: '/livestreams',
          element: isUser ? <UserLiveStreams /> : <LiveStreams />
        },
        (isAdmin || isLiveStreamAdmin) && {
          path: '/schedule',
          element: <Schedule />
        },
        isAdmin && {
          path: '/users',
          element: <Users />
        },
        isAdmin && {
          path: '/basicinfo',
          element: <BasicInfo />
        },
        {
          path: '/about',
          element: <About />
        },
        {
          path: '/*',
          element: <Video />
        }
      ]
    },
    {
      path: '/videoembed',
      element: 
        <AuthGuard>
          <VideoEmbed />
        </AuthGuard>
    },
    { path: '*', element: <Navigate to="/" replace /> }
  ].filter(i => i));
}

const MainPage = Loadable(lazy(() => import('../pages/MainPage')));
const Upload = Loadable(lazy(() => import('../pages/Upload')));
const UploadBanner = Loadable(lazy(() => import('../pages/UploadBanner')));
const Video = Loadable(lazy(() => import('../pages/Video')));
const VideoEmbed = Loadable(lazy(() => import('../pages/VideoEmbed')));
const VideoEdit = Loadable(lazy(() => import('../pages/VideoEdit')));
const Approve = Loadable(lazy(() => import('../pages/Approve')));
const MyVideos = Loadable(lazy(() => import('../pages/MyVideos')));
const Logout = Loadable(lazy(() => import('../pages/Logout')));
const Reply = Loadable(lazy(() => import('../pages/Reply')));
const Categories = Loadable(lazy(() => import('../pages/Categories')));
const BusinessLines = Loadable(lazy(() => import('../pages/BusinessLines')));
const CategoryVideos = Loadable(lazy(() => import('../pages/CategoryVideos')));
const Search = Loadable(lazy(() => import('../pages/Search')));
const Featured = Loadable(lazy(() => import('../pages/Featured')));
const Posts = Loadable(lazy(() => import('../pages/Posts')));
const Tags = Loadable(lazy(() => import('../pages/Tags')));
const Countries = Loadable(lazy(() => import('../pages/Countries')));
const HotTopics = Loadable(lazy(() => import('../pages/HotTopics')));
const Statistics = Loadable(lazy(() => import('../pages/Statistics')));
const StatisticsDetail = Loadable(lazy(() => import('../pages/StatisticsDetail')));
const ApproveDetail = Loadable(lazy(() => import('../pages/ApproveDetail')));
const ApproveRedirect = Loadable(lazy(() => import('../pages/ApproveRedirect')));
const LiveStreams = Loadable(lazy(() => import('../pages/LiveStreams')));
const UserLiveStreams = Loadable(lazy(() => import('../pages/UserLiveStreams')));
const Schedule = Loadable(lazy(() => import('../pages/Schedule')));
const VideoLibrary = Loadable(lazy(() => import('../pages/VideoLibrary')));
const LikedVideos = Loadable(lazy(() => import('../pages/LikedVideos')));
const WatchLaterVideos = Loadable(lazy(() => import('../pages/WatchLaterVideos')));
const Users = Loadable(lazy(() => import('../pages/Users')));
const FeaturedAdmin = Loadable(lazy(() => import('../pages/FeaturedAdmin')));
const Slideshow = Loadable(lazy(() => import('../pages/Slideshow')));
const Delete = Loadable(lazy(() => import('../pages/Delete')));
const UserVideos = Loadable(lazy(() => import('../pages/UserVideos')));
const BasicInfo = Loadable(lazy(() => import('../pages/BasicInfo')));
const About = Loadable(lazy(() => import('../pages/About')));