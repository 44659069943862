import { Icon } from '@iconify/react';
import { useSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import homeFill from '@iconify/icons-eva/home-fill';
import heart from '@iconify/icons-eva/heart-outline';
import infoIcon from '@iconify/icons-eva/info-outline';
import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import arrowIcon from '@iconify/icons-eva/diagonal-arrow-right-up-fill'
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { alpha } from '@material-ui/core/styles';
import { Button, Box, Divider, MenuItem, Typography } from '@material-ui/core';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useAuth from '../../hooks/useAuth';
import useIsMountedRef from '../../hooks/useIsMountedRef';
// components
import { MIconButton } from '../../components/@material-extend';
import MyAvatar from '../../components/MyAvatar';
import MenuPopover from '../../components/MenuPopover';
import { config } from '../../_config';
import { PublicClientApplication } from '@azure/msal-browser'

// ----------------------------------------------------------------------


// ----------------------------------------------------------------------

export default function AccountPopover({ ...props }) {
  const navigate = useNavigate();
  const anchorRef = useRef(null);
  const { logout, user } = useAuth();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("")
  const role = localStorage.getItem('role')
  const isUser = role === 'user'

  const MENU_OPTIONS = [
    isUser && {
      label: 'My uploaded videos',
      icon: homeFill,
      linkTo: '/myvideos',
      isMobile: true,
      isPC: true
    },
    isUser && {
      label: 'Liked videos',
      icon: heart,
      linkTo: '/likedvideos',
      isMobile: true,
      isPC: true
    },
    isUser && {
      label: 'Watch later videos',
      icon: arrowIcon,
      linkTo: '/watchlater',
      isMobile: true,
      isPC: true
    },
    isUser && {
      label: 'My videos',
      icon: homeFill,
      linkTo: '/uservideos',
      isMobile: false,
      isPC: false
    },
    isUser && {
      label: 'About DeloitteTUBE',
      icon: infoIcon,
      linkTo: '/about',
      isMobile: true,
      isPC: true
    }
    // {
    //   label: 'Profile',
    //   icon: personFill,
    //   linkTo: PATH_DASHBOARD.root
    // },
    // {
    //   label: 'Settings',
    //   icon: settings2Fill,
    //   linkTo: PATH_DASHBOARD.user.account
    // }
  ].filter(i => i);


  useEffect(() => {
    setName(localStorage.getItem('name'))
    setEmail(localStorage.getItem('email'))
  }, [localStorage])

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      // await logout?.();
      const _config = {
          auth: {
              clientId: config.appId,
              redirectUri: config.redirectUri, // defaults to application start page
              postLogoutRedirectUri: config.logoutRedirectUri
          }
      }
      
      const myMsal = new PublicClientApplication(_config);
      
      // you can select which account application should sign out
      const logoutRequest = {
          account: myMsal.getAccountByHomeId(localStorage.getItem('sid'))
      }
      await logout?.();
      localStorage.setItem('isAuthenticated', 'false')
      localStorage.clear();
      await myMsal.logoutRedirect(logoutRequest);
      if (isMountedRef.current) {
        // navigate('/');
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout', { variant: 'error' });
    }
  };

  return (
    <Box sx={{
      ...props?.sx
    }}>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          mt: [0.5, 1.7, 1.7],
          ml: 2,
          mr: -2,
          width: [40, 50, 50],
          height: [40, 50, 50],
          display: ['flex', 'inline-flex', 'inline-flex'],
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
            }
          }),
        }}
      >
        <MyAvatar />
      </MIconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 350, position: 'relative', zIndex: '100009 !important' }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {/* {`${user?.firstname} ${user?.lastname}`} */}
            {name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {/* {user?.email} */}
            {email}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: 'body2', py: 1, px: 2.5, display: [option.isMobile ? 'flex' : 'none', option.isPC ? 'flex' : 'none', option.isPC ? 'flex' : 'none'] }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24
              }}
            />

            {option.label}
          </MenuItem>
        ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="outlined" onClick={handleLogout}>
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </Box>
  );
}
