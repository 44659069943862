import SvgIcon from '@mui/material/SvgIcon';
import RoomRoundedIcon from '@material-ui/icons/RoomRounded';
import VpnKeyRoundedIcon from '@material-ui/icons/VpnKeyRounded';
import PaletteRoundedIcon from '@material-ui/icons/PaletteRounded';
import StorageRoundedIcon from '@material-ui/icons/StorageRounded';
import PieChartRoundedIcon from '@material-ui/icons/PieChartRounded';
import PollRoundedIcon from '@material-ui/icons/PollRounded';
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';

import { Icon } from '@iconify/react';
// routes
// import { PATH_DASHBOARD } from '../../routes/paths';
// components
// import SvgIconStyle from '../../components/SvgIconStyle';
// import useLocales from '../../hooks/useLocales';
// import { docsHost } from '../../config';

// ----------------------------------------------------------------------

// const getIcon = (name: string) => (
//   <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
// );

const getMaterialIcon = (component: any) => (
  <SvgIcon component={component} sx={{ width: '100%', height: '100%' }} />
);

const getIconifyIcon = (name: string) => <Icon icon={name} style={{ fontSize: '22px' }} />;

const ICONS = {
  //   blog: getIcon('ic_blog'),
  //   cart: getIcon('ic_cart'),
  //   chat: getIcon('ic_chat'),
  //   mail: getIcon('ic_mail'),
  //   // user: getIcon('ic_user'),
  //   user: getMaterialIcon(PersonRoundedIcon),
  //   calendar: getIcon('ic_calendar'),
  //   ecommerce: getIcon('ic_ecommerce'),
  //   analytics: getMaterialIcon(TrendingUpIcon),
  //   dashboard: getIcon('ic_dashboard'),
  //   kanban: getIcon('ic_kanban'),
  map: getMaterialIcon(RoomRoundedIcon),
  key: getMaterialIcon(VpnKeyRoundedIcon),
  style: getMaterialIcon(PaletteRoundedIcon),
  dataset: getMaterialIcon(StorageRoundedIcon),
  statistic_map: getMaterialIcon(PieChartRoundedIcon),
  statistic_user: getMaterialIcon(PollRoundedIcon),
  //   billing: getIcon('ic_receipt'),
  requests: getMaterialIcon(CloudUploadIcon),
  rates: getMaterialIcon(LocalOfferIcon)
};

export const useSidebarConfig = () => ({
  sidebarConfig: [
    {
      subheader: '',
      items: [
        {
          title: 'title1',
          path: '/',
          icon: ICONS.map
        }
      ].filter((i) => i)
    }
  ].filter((i) => i)
});
