import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import axios from 'utils/axios';

export const mapsApi = createApi({
  reducerPath: 'pokemonApi',
  baseQuery: fetchBaseQuery({ baseUrl: 'https://pokeapi.co/api/v2/' }),
  endpoints: (builder) => ({
    getMaps: builder.query<any, string>({
      query: () => `/`
    })
  })
});

export const { useGetMapsQuery } = mapsApi;

export const fetchUsers = createAsyncThunk('users/fetchUsers', async () => {
  const response = await axios.get('keys');
  return response.data;
});

const counterSlice = createSlice({
  name: 'counter',
  initialState: {
    value: 0,
    users: [] as Array<any>
  },
  reducers: {
    incremented: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1;
    },
    decremented: (state) => {
      state.value -= 1;
    },
    incrementByAmount: (state, action) => {
      state.value -= action.payload;
    }
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      // Add user to the state array
      state.users.push(...action.payload.data);
    });
  }
});

export const { incremented, decremented, incrementByAmount } = counterSlice.actions;

export default counterSlice.reducer;
